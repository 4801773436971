import React, { Component } from "react"
import { Header } from "./screen-components/PageHeader"

class PageNotFound extends Component {
  render() {
    console.log("Page Not Found...")
    return (
      <div>
        <Header />
        <div className="body-container">
          <div className="body-container-body">
            <div className="not-found">
              <h1>Page Not Found</h1>
              <p>The page you are looking for does not exist.</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PageNotFound
