import OmniAural from "omniaural"
import React from "react"
import { Themes } from "../managers/ThemeManager"
import { Header } from "./screen-components/PageHeader"
import { BackArrowButton } from "./screen-components/BackArrowButton"

const TermsPage = () => {
  const termsAndConditionsContent = () => {
    return (
      <div>
        <h1>{`END USER LICENSE AGREEMENT AND TERMS & CONDITIONS`}</h1>
        <h2>Application Terms of Use Agreement</h2>
        <p>{Themes.currentTheme().strings.termsOfUseAgreement}</p>
        <h2>Privacy Policy</h2>
        <p>{Themes.currentTheme().strings.privacyPolicy}</p>
      </div>
    )
  }

  return (
    <div>
      <Header/>
      <div className="body-container">
        <div className="terms-content">
          <BackArrowButton/>
          {termsAndConditionsContent()}
        <div className="version-container">
          <div className="version-text">
            Website: v{require("../../package.json").version}
          </div>
          <div className="version-text">
            Server: v{OmniAural.state?.config?.version?.value()}
          </div>
        </div>
        </div>
      </div>
    </div>
  )
}

export default TermsPage
