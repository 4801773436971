import React from "react"
import { Themes } from "../../managers/ThemeManager"

const BulletList = (props) => {
  const images = Themes.currentTheme().images
  const listOut = props.items.map((item, index) => (
    <li key={`bullet_item_${index}`} style={{ listStyleImage: `url(${images.bulletArrow})` }}>
      {item}
    </li>
  ))
  return (
    <div>
      <ul>{listOut}</ul>
    </div>
  )
}

export default BulletList
