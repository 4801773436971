import { withRouter } from "react-router-dom"
import { Constants } from "../Constants"
import { Themes } from "../managers/ThemeManager"
import UseRawHTMLContent from "./HTMLRenderer"

const AssertionRedirect = ({ history }) => {
  const state = history?.location?.state || {}
  let { acs_url = "", acs_params = {} } = state

  if (Constants.IS_DEV) {
    acs_url = process.env.REACT_APP_ACS_DOMAIN + acs_url
  }

  let inputs = ""
  for (let key in acs_params) {
    inputs += `
        hiddenField = document.createElement('input')
        hiddenField.setAttribute( 'type', 'hidden')
        hiddenField.setAttribute( 'name', '${key}')
        hiddenField.setAttribute( 'value', '${acs_params[key]}')
        form.appendChild(hiddenField)
    `
  }

  const htmlString = `<div style="color:white;margin:30px">
  <form id="assertion_form" action="${acs_url}" accept-charset="UTF-8" method="post" style="color:black;margin:30px">
    Processing request...
    </br>
    </br>
    Click
    <input
      type="submit"
      value="here"
      style="
          background: none !important;
          color: ${Themes.currentTheme().colors.loginButton};
          border: none;
          text-decoration: underline;
          padding: 0 !important;
          font: inherit;
          cursor: pointer;
        "
      data-disable-with="here"
    />
    if you are not redirected automatically.
  </form>
  <script>
    form = document.getElementById('assertion_form')
    ${inputs}
    form.submit()
  </script>
</div>`

  return <UseRawHTMLContent id="assertion_html" html={htmlString} />
}

export default withRouter(AssertionRedirect)
