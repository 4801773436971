import { useOmniAural } from "omniaural"

export const ContactInfoText = () => {
  const [config] = useOmniAural("config")
  const contactString = config.helpdesk_contact

  // This function accepts a string and detects if it contains a URL. 
  // If it does, it will surround the URL with an anchor tag.
  // If it does not, it will return the string as-is.
  const detectAndSurroundURLInString = () => {
    const urlRegex = /(https?:\/\/[^\s]+)/g
    const url = contactString?.match(urlRegex)
    if (url) {
      const link = url[0]
      const urlStartIndex = contactString?.indexOf(link)
      const urlEndIndex = urlStartIndex + link.length
      const strBeforeURL = contactString?.substring(0, urlStartIndex)
      const strAfterURL = contactString?.substring(urlEndIndex, contactString?.length)

      return (
        <span>
          {strBeforeURL}
          <a className="bold" rel="noreferrer" target="_blank" href={link}>
            {link}
          </a>
          {strAfterURL}
        </span>
      )
    } else {
      return contactString
    }
  }

  return <p>Please contact {detectAndSurroundURLInString()}</p>
}
