import React from 'react'

const DeviceDetect = () => {
    let deviceType = "Desktop"

    if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
          navigator.userAgent
        )
      ) {
        deviceType = "Mobile"
    }

    if(deviceType === "Mobile"){
        if (/iPhone|iPad|iPod/i.test(navigator.userAgent)){
            return <div onClick={() => window.location.assign("https://apps.apple.com/us/app/army-mobileconnect/id1544271575")} id="apple-store-img" />
        }
        else{
            return <div onClick={() => window.location.assign("https://play.google.com/store/apps/details?id=com.army.armyauth&hl=en_US&gl=US")} id="google-play-img" />
        }
    }
    else{
        return(
            <>
                <div id="apple-store-img" />
                <div id="google-play-img" />
            </>
        )
    }

}

export default DeviceDetect