import React, { Component } from "react"
import OmniAural from "omniaural"
import { Themes } from "../managers/ThemeManager"
import { PollingManager } from "../managers/PollingManager"
import { challenge2ndFactor, getAssertionWithTotp } from "../services"
import "./Challenge.css"
import { ChallengeHeader } from "./screen-components/ChallengeHeader"
import { ContactInfoText } from "../components/ContactInfoText"

const { Constants } = require("../Constants.js")

class Challenge extends Component {
  constructor() {
    super()
    this.timerRef = React.createRef()
    this.state = {
      notResponded: "none",
      enterCode: "none",
      defaultTitle: "block",
      defaultSubtitle: "block",
      totpCodeContainer: "none",
      successTitle: "none",
      deniedTitle: "none",
      successSubtitle: "none",
      deniedSubtitle: "none",
      enteredTotp: "",
      timedOutSubtitle: "none",
      timedOutButton: "none",
      invalidCode: "none",
      device_name: "",
      challenge_uuid: "",
      hideOTPEntry:true
    }
  }

  images = Themes.currentTheme().images

  async componentDidMount() {
    const user = OmniAural.state.user?.value() || {}
    this.startCheckingState()
    challenge2ndFactor(user?.uid).then((resp) => {
      this.shadowTOTPCheck()
      const { device_name, uuid } = resp

      let assertionParams = {}
      if (window.location.search) {
        const params = new URLSearchParams(window.location.search)
        const acs_relay = params.get("acs_relay")
        if (acs_relay) {
          assertionParams["acs_relay"] = encodeURIComponent(acs_relay)
        }
      }

      if (uuid) {
        assertionParams["uuid"] = uuid
      }

      this.setState({ device_name, challenge_uuid: uuid }, () => {
        PollingManager.startAssertionPolling(
          user?.uid,
          assertionParams,
          this.assertionSuccess,
          this.assertionError
        )
      })
    })
  }

  assertionSuccess = (response) => {
    this.setState({
      successTitle: "block",
      successSubtitle: "block",
      enterCode: "none",
      defaultTitle: "none",
      defaultSubtitle: "none",
      totpCodeContainer: "none"
    })

    this.props.history.push({
      pathname: "/process_assertion",
      state: {
        acs_url: response.acs_url,
        acs_params: response.acs_params,
        forwardPath: this.props.history.location?.state?.forwardPath
      }
    })
  }

  assertionError = (error) => {
    if (error.code === Constants.ERROR_CODES.POLLING_TIMEOUT) {
      this.setState({
        deniedTitle: "none",
        deniedSubtitle: "none",
        notResponded: "none",
        enterCode: "none",
        defaultTitle: "none",
        defaultSubtitle: "none",
        totpCodeContainer: "none",
        timedOutSubtitle: "block",
        timedOutButton: "block"
      })
    } else if (error.code === Constants.ERROR_CODES.CHALLENGE_DENIED) {
      clearTimeout(this.timerRef.current)
      this.setState({
        deniedTitle: "block",
        deniedSubtitle: "block",
        notResponded: "none",
        enterCode: "none",
        defaultTitle: "none",
        defaultSubtitle: "none",
        totpCodeContainer: "none"
      })
    }
  }

  showCodeForm = () => {
    this.setState({
      totpCodeContainer: "block",
      enterCode: "none"
    })
  }

  checkState = () => {
    const nonResponseTime = 15
    const enterCodeTime = 5

    let timerCount = this.state.timerCount
    if (timerCount > nonResponseTime && !this.state.hideOTPEntry) {
      this.setState({
        notResponded: "flex",
        defaultSubtitle: "none"
      })
    } else if (timerCount > enterCodeTime && this.state.totpCodeContainer !== "block" && !this.state.hideOTPEntry) {
      this.setState({ enterCode: "block" })
      this.timerRef.current = setTimeout(this.checkState, 1000)
    } else {
      this.timerRef.current = setTimeout(this.checkState, 1000)
    }
    this.setState({ timerCount: timerCount + 1 })
  }

  startCheckingState = () => {
    this.setState({ timerCount: 0 })
    setTimeout(this.checkState, 1000)
  }

  shadowTOTPCheck = async () => {
    if (Constants.IS_DEV) {
      this.setState({hideOTPEntry:false, enterCode:"block"})
      return
    }

    try {
      let assertionParams = {}
      if (window.location.search) {
        const params = new URLSearchParams(window.location.search)
        const acs_relay = params.get("acs_relay")
        if (acs_relay) {
          assertionParams["acs_relay"] = encodeURIComponent(acs_relay)
        }
      }

      if (this.state.challenge_uuid) {
        assertionParams["uuid"] = this.state.challenge_uuid
      }

      const response = await getAssertionWithTotp(
        OmniAural.state.user?.uid?.value(),
        "totpcheck",
        assertionParams
      )

      if(response.code !== Constants.ERROR_CODES.INVALID_TOTP_RESET) {
        this.setState({enterCode: "block", hideOTPEntry:false})
      }
    } catch (err) {
      
    }
  }

  performTOTPCheck = (e) => {
    e.preventDefault()
    let assertionParams = {}
    if (window.location.search) {
      const params = new URLSearchParams(window.location.search)
      const acs_relay = params.get("acs_relay")
      if (acs_relay) {
        assertionParams["acs_relay"] = encodeURIComponent(acs_relay)
      }
    }

    if (this.state.challenge_uuid) {
      assertionParams["uuid"] = this.state.challenge_uuid
    }

    getAssertionWithTotp(
      OmniAural.state.user?.uid?.value(),
      this.state.enteredTotp,
      assertionParams
    ).then((response) => {
      if (response.code === "1200") {
        this.assertionSuccess(response)
      } else {
        this.setState({ invalidCode: "block" })
        console.log("assertion error: ", response)
      }
    })
  }

  render() {
    return (
      <div>
        <div className="challenge-container">
          <ChallengeHeader />

          <div className="body-container">
            <div className="challenge-body-outer">
              <div className="challenge-body-inner">
                <h2 style={{ display: this.state.defaultTitle }}>
                  {`An authentication request has been sent to 
                  ${this.state.device_name}. Approve to log in.`}
                  <h5 style={{ display: this.state.defaultSubtitle }}>
                    {Themes.currentTheme().strings.challenge_default_subtitle}
                  </h5>
                </h2>

                <h1 style={{ display: this.state.successTitle }}>Authentication successful.</h1>
                <h1 style={{ display: this.state.deniedTitle }}>Authentication denied.</h1>

                <p style={{ display: this.state.successSubtitle }}>You will be redirected.</p>
                <p style={{ display: this.state.deniedSubtitle }}>Please try logging in again.</p>
                <p id="timeout-message" style={{ display: this.state.timedOutSubtitle }}>
                  <h5>Your authentication attempt has timed out.</h5>
                </p>
                <div className="return-to-login" style={{ display: this.state.timedOutButton }}>
                  <form action="/login">
                    <button className="challenge-button return-to-login">RETURN TO LOGIN</button>
                  </form>
                </div>
                <div
                  className="auth-not-responded-container"
                  style={{ display: this.state.notResponded }}>
                  <div className="auth-not-responded-icon-container">
                    <img
                      src={Themes.currentTheme().images.bellIcon}
                      className="auth-not-responded-icon"></img>
                  </div>
                  <h5>{Themes.currentTheme().strings.not_responded_msg}</h5>
                </div>
                <div className="enter-code-and-cancel-container">
                  <div className="enter-code-form" style={{ display: this.state.enterCode }}>
                    <h5 className="fake-link" onClick={this.showCodeForm}>
                      Enter code instead
                    </h5>
                  </div>

                  <div
                    className="entry-container"
                    style={{ display: this.state.totpCodeContainer }}>
                    <div className="entry-subcontainer">
                      <label className="challenge-label">
                        <p>Enter code</p>
                        <input
                          className="challenge-input"
                          type="password"
                          name="totp"
                          id="totp"
                          onChange={(e) => this.setState({ enteredTotp: e.target.value })}
                        />
                      </label>
                      <button
                        className="challenge-button"
                        style={{ color: Themes.currentTheme().colors.buttonText }}
                        onClick={this.performTOTPCheck}>
                        SUBMIT
                      </button>
                    </div>
                    <p style={{ display: this.state.invalidCode }}>
                      The code you entered is not valid.
                    </p>
                  </div>

                  <a href="/logout">
                    <h5>Cancel and sign out</h5>
                  </a>
                </div>
                <p>Need assistance?</p>
                <ContactInfoText/>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Challenge