import React from "react"
import { Themes } from "../managers/ThemeManager"
import { Header } from "./screen-components/PageHeader";
import { BackArrowButton } from "./screen-components/BackArrowButton";

const FAQPage = () => {
  const linkify = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    return text.replace(urlRegex, function(url) {
      return '<a href="' + url + '">' + url + '</a>';
    })
  }

  let items = []
  Themes.currentTheme().faq_items.forEach((item, index) => {
    let answer = linkify(item.answer)

    items.push(
    <div className='question' key={`${index}`}>
        <div className='question-question'>{item.question}</div>
        <div style={{whiteSpace:"pre-line"}} className='question-answer' dangerouslySetInnerHTML={{__html: answer}}/>
        {item.image? <img className='question-image' src={item.image} /> : ''}
    </div>)
  })

  const faqContent = () => {
    return (
      <div>
        <h1>{`FREQUENTLY ASKED QUESTIONS`}</h1>
        <div>
          {items}
        </div>
      </div>
    )
  }

  return (
    <div>
      <Header />
      <div className="body-container">
        <div className="faq-content">
          <BackArrowButton />
          {faqContent()}
        </div>
      </div>
    </div>
  )
}

export default FAQPage
