import React, { Component } from "react"
import OmniAural from "omniaural"
import { Themes } from "../managers/ThemeManager"
import { PollingManager } from "../managers/PollingManager"
import { spfSendSMS } from "../services"
import { getPrimaryFactorQRData } from "../services"
import { QRCodeComponent } from "../components/QRCodeComponent"
import queryString from "query-string"
import Bullet1 from "../assets/Bullet1.svg"
import Bullet2 from "../assets/Bullet2.svg"
import DeviceDetect from "../components/DeviceDetect"
import { ChallengeHeader } from "./screen-components/ChallengeHeader"
import { ContactInfoText } from "../components/ContactInfoText"

const { Constants } = require("../Constants.js")

class SponsoredRegisterPFA extends Component {
  constructor() {
    super()
    this.timerRef = React.createRef()
    this.state = {
      notResponded: "none",
      enterCode: "block",
      defaultTitle: "block",
      defaultSubtitle: "block",
      totpCodeContainer: "block",
      successTitle: "none",
      deniedTitle: "none",
      deniedSubtitle: "none",
      enteredTotp: "",
      timedOutSubtitle: "none",
      timedOutButton: "none",
      invalidCode: "none",
      qrCodeExpired: true,
      qrCode: null
    }

    OmniAural.register(this, "config")
  }

  images = Themes.currentTheme().images

  componentDidMount() {
    this.sendTOTPSMS()
  }

  sendTOTPSMS = () => {
    this.setState(
      {
        notResponded: "none",
        enterCode: "block",
        defaultTitle: "block",
        defaultSubtitle: "block",
        successTitle: "none",
        totpCodeContainer: "block",
        enteredTotp: "",
        timedOutSubtitle: "none",
        timedOutButton: "none",
        invalidCode: "none",
        qrCodeExpired: true,
        qrCode: null
      },
      () => {
        this.startCheckingState()
        let params = queryString.parse(this.props.location.search)
        let uuid = params.uuid
        spfSendSMS(uuid)
      }
    )
  }

  assertionError = (error) => {
    if (error.code === Constants.ERROR_CODES.POLLING_TIMEOUT) {
      this.setState({
        deniedTitle: "none",
        deniedSubtitle: "none",
        notResponded: "none",
        enterCode: "none",
        defaultTitle: "none",
        defaultSubtitle: "none",
        totpCodeContainer: "none",
        timedOutSubtitle: "block",
        timedOutButton: "block"
      })
    } else if (error.code === Constants.ERROR_CODES.CHALLENGE_DENIED) {
      clearTimeout(this.timerRef.current)
      this.setState({
        deniedTitle: "block",
        deniedSubtitle: "block",
        notResponded: "none",
        enterCode: "none",
        defaultTitle: "none",
        defaultSubtitle: "none",
        totpCodeContainer: "none"
      })
    }
  }

  checkState = () => {
    const nonResponseTime = 15
    const enterCodeTime = 5

    let timerCount = this.state.timerCount
    if (timerCount > nonResponseTime && this.state.successTitle !== "block") {
      this.setState({
        notResponded: "flex",
        defaultSubtitle: "none"
      })
    } else if (timerCount > enterCodeTime && this.state.totpCodeContainer !== "block") {
      this.setState({ enterCode: "block" }, () => {
        this.timerRef.current = setTimeout(this.checkState, 1000)
      })
    } else {
      this.timerRef.current = setTimeout(this.checkState, 1000)
    }

    this.setState({ timerCount: timerCount + 1 })
  }

  startCheckingState = () => {
    this.setState({ timerCount: 0 }, () => {
      this.timerRef.current = setTimeout(this.checkState, 1000)
    })
  }

  getNewQRCode = async () => {
    let params = queryString.parse(this.props.location.search)
    let uuid = params.uuid

    getPrimaryFactorQRData(uuid, this.state.enteredTotp)
      .then((data) => {
        if (data.code !== "1200") {
          const error = new Error(data.error_message)
          error.code = data.code
          throw error
        }
        if (Constants.IS_DEV) {
          console.log("Data url: ", data.url)
        }

        this.setState(
          {
            qrCode: data.url,
            qrCodeExpired: false,
            successTitle: "block",
            enterCode: "none",
            defaultTitle: "none",
            defaultSubtitle: "none",
            totpCodeContainer: "none",
            notResponded: "none"
          },
          () => {
            PollingManager.startPFRegistrationStatusPolling(
              { uid: this.state.user?.uid, uuid: uuid },
              () => {
                this.props.history.push({
                  pathname: "/spfa_registered"
                })
              },
              (e) => {
                this.setState({ qrCodeExpired: true, errorMessage: e.message })
              }
            )
          }
        )
      })
      .catch((error) => {
        if (`${error.code}` === Constants.ERROR_CODES.INVALID_TOTP) {
          this.setState({
            invalidCode: "block",
            errorMessage: error.message,
            qrCodeExpired: true
          })
        } else {
          this.setState({
            errorMessage: "An error occurred generating a QR Code.",
            qrCodeExpired: true
          })
        }
      })
  }

  render() {
    const qrSize = this.state.config.qr_code_size || Constants.DEFAULT_QR_SIZE
    return (
      <div>
        <div className="challenge-container">
          <ChallengeHeader />

          <div className="body-container">
            <div className="challenge-body-outer">
              <div className="challenge-body-inner">
                <h2 style={{ display: this.state.defaultTitle }}>
                  {`Please enter the verification code sent to your device.`}
                  <div style={{ display: this.state.defaultSubtitle }}>
                    {Themes.currentTheme().strings.challenge_default_subtitle}
                  </div>
                </h2>

                <div className="auth-success" style={{ display: this.state.successTitle }}>
                  <h1>Registration Steps: </h1>
                  <div className="all-instructions">
                    <div className="one-instruction">
                      <div className="one-instruction-img-container">
                        <img src={Bullet1} alt="bullet point 1" />
                      </div>
                      <div className="instruction-content">
                        <h6 className="instruction-title">Download</h6>
                        <div className="instruction-text">
                          <p>Download the MobileConnect app on your phone or tablet</p>
                          <p className="device-requirements">
                            Requires iOS 12.1 / Android 9 or newer
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="apple-google-imgs">
                      <DeviceDetect />
                    </div>

                    <div className="one-instruction">
                      <div className="one-instruction-img-container">
                        <img src={Bullet2} alt="bullet point 1" />
                      </div>
                      <div className="instruction-content">
                        <h6>Register</h6>
                        <div className="mobile-link-container"></div>
                        <p>Launch the app and follow the instructions.</p>
                      </div>
                    </div>
                    {this.state.qrCodeExpired ? (
                      <div className="expired-qr" style={{ height: qrSize, width: qrSize }}>
                        <div className="expired-qr-text">
                          <h1>QR CODE EXPIRED</h1>
                          <h2 id="expired-qr-h2" onClick={this.sendTOTPSMS}>
                            Refresh Code
                          </h2>
                        </div>
                      </div>
                    ) : this.state.qrCode !== null ? (
                      <QRCodeComponent data={this.state.qrCode} size={qrSize} />
                    ) : (
                      <div
                        style={{
                          background: Themes.currentTheme().colors.lighterMediumBase,
                          height: qrSize,
                          width: qrSize
                        }}></div>
                    )}
                  </div>
                </div>

                <p id="timeout-message" style={{ display: this.state.timedOutSubtitle }}>
                  Your authentication attempt has timed out.
                </p>
                <div className="return-to-login" style={{ display: this.state.timedOutButton }}>
                  <form action="/login">
                    <button className="challenge-button return-to-login">RETURN TO LOGIN</button>
                  </form>
                </div>
                <div
                  className="auth-not-responded-container"
                  style={{ display: this.state.notResponded }}>
                  <div className="auth-not-responded-icon-container">
                    <img
                      src={Themes.currentTheme().images.bellIcon}
                      className="auth-not-responded-icon"></img>
                  </div>
                  <h5 style={{ color: "white" }}>
                    {Themes.currentTheme().strings.not_responded_sms_msg}
                  </h5>
                </div>
                <div className="enter-code-and-cancel-container">
                  <div
                    className="entry-container"
                    style={{ display: this.state.totpCodeContainer }}>
                    <div className="entry-subcontainer">
                      <label className="challenge-label">
                        <p>Enter code</p>
                        <input
                          className="challenge-input"
                          type="password"
                          name="totp"
                          id="totp"
                          value={this.state.enteredTotp}
                          onChange={(e) => this.setState({ enteredTotp: e.target.value })}
                        />
                      </label>
                      <button
                        className="challenge-button"
                        style={{ color: Themes.currentTheme().colors.buttonText }}
                        onClick={this.getNewQRCode}>
                        SUBMIT
                      </button>
                    </div>
                    <p style={{ display: this.state.invalidCode, color: "red" }}>
                      The code you entered is not valid.
                    </p>
                  </div>

                  <a href="/login">
                    <h5>Cancel</h5>
                  </a>
                </div>
                <p>
                  <b>Need assistance?</b>
                  <ContactInfoText/>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SponsoredRegisterPFA
