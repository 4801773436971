import React from "react"
import OmniAural from "omniaural"

const ClassificationBanner = (props) => {
  const levelData = OmniAural.state.config?.level_data?.value()
  const pos = props.position || "top"
  let bannerStyle = {
    width: "100%",
    zIndex: "2",
    color: levelData.color,
    backgroundColor: levelData.bg_color,
    textAlign: "center",
    padding: "4px 0",
    fontWeight: "bold"
  }

  if (pos === "bottom") {
    bannerStyle["position"] = "fixed"
    bannerStyle["bottom"] = 0
  } else if (pos === "top") {
    bannerStyle["position"] = "sticky"
    bannerStyle["top"] = 0
  }

  return <div style={bannerStyle}>{levelData.level + levelData.control}</div>
}

export default ClassificationBanner
