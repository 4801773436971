export const SuccessfulRegistrationBanner = ({ description }) => {
  return (
    <div id="registered-top-container-content">
      <div id="registered-title-top">
        <div id="registered-title">Success!</div>
        <div id="registered-subtitle">You are now registered.</div>
      </div>
      <div id="registered-title-middle">
        <div className="half-max-width">{description}</div>
      </div>
    </div>
  )
}
