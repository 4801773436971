import React, { Component } from "react"
import OmniAural from "omniaural"

import { withPermissions } from "../components/AuthenticationComponent"
import DeviceDetect from "../components/DeviceDetect"
import { getSecondFactorQRData } from "../services"
import { PollingManager } from "../managers/PollingManager"
import { Themes } from "../managers/ThemeManager"
import { Constants } from "../Constants"
import BulletList from "./screen-components/BulletList"
import Bullet1 from "../assets/Bullet1.svg"
import Bullet2 from "../assets/Bullet2.svg"
import Bullet3 from "../assets/Bullet3.svg"
import { QRCodeComponent } from "../components/QRCodeComponent"
import { Header } from "./screen-components/PageHeader"
import { ContactInfoText } from "../components/ContactInfoText"

class Register extends Component {
  constructor() {
    super()
    this.state = {
      qrCodeExpired: false,
      qrCode: null,
      errorMessage: null
    }

    OmniAural.register(this, ["user", "config"])
  }

  componentDidMount() {
    if(this.state.config?.theme === "airforce") {
      this.props.history.push({
        pathname: "/register_pfa"
      })
    } else if (OmniAural.isUserMFARegistered()) {
      this.props.history.push({
        pathname: "/successfully_registered"
      })
    } else {
      this.getNewQRCode()
    }
  }

  getNewQRCode = () => {
    this.setState({ qrCodeExpired: false, qrCode: null })

    let uid = ""
    // uid is only required in development
    if (Constants.IS_DEV) {
      uid = this.state.user?.uid
    }

    getSecondFactorQRData(uid)
      .then((data) => {
        if (Constants.IS_DEV) {
          console.log(data.qr_data)
        }

        this.setState({ qrCode: data.qr_data, qrCodeExpired: false }, () => {
          PollingManager.startRegistrationStatusPolling(
            uid,
            () => {
              this.props.history.push({
                pathname: "/successfully_registered",
                state: { prevPath: window.location.pathname }
              })
            },
            (e) => {
              this.setState({ qrCodeExpired: true })
            }
          )
        })
      })
      .catch((error) => {
        this.setState({
          errorMessage: "An error occurred generating a QR Code.",
          qrCodeExpired: true
        })
        console.log("Error generating QR Code: ", error)
      })
  }

  createBulletListItems = (strIn) => {
    const arrOut = []
    Object.keys(Themes.currentTheme().strings).forEach((entry) => {
      if (entry.includes(strIn) && Themes.currentTheme().strings[entry].length !== 0) {
        arrOut.push(Themes.currentTheme().strings[entry])
      }
    })
    return arrOut
  }

  render() {
    const images = Themes.currentTheme().images
    const qrSize = this.state.config.qr_code_size || Constants.DEFAULT_QR_SIZE
    return (
      <div>
        <Header />
        <img src={images.appHeroImage} className="app-hero-image" alt="app hero" />

        <div className="body-container">
          <div className="body-container-head mobile-hidden">
            <h5 className="bold">
              {`Instant, secure logins to ${
                Themes.currentTheme().strings.enterpriseAbbrev
              } using an app on your personal mobile device.`}
            </h5>
          </div>
          <div className="body-container-body mobile-hidden">
            <BulletList items={this.createBulletListItems("register_instructions")} />
          </div>
          <div className="body-container-register">
            <h2 id="user-status">
              My Status: {OmniAural.isUserMFARegistered() ? "Registered" : "Not Registered"}
            </h2>
          </div>
          <div className="body-container-get-started">
            <h1>GET STARTED</h1>
            <h3>Registering and setting up your device only takes a minute.</h3>
            <div className="body-container-content">
              <div className="all-instructions-container">
                <div className="one-instruction">
                  <div className="one-instruction-img-container">
                    <img src={Bullet1} alt="bullet point 1" />
                  </div>
                  <div className="instruction-content">
                    <h6 className="instruction-title">Download</h6>
                    <div className="instruction-text">
                      <p>Download the app on your phone or tablet</p>
                      <p className="device-requirements">Requires iOS 12.1 / Android 9 or newer</p>
                    </div>
                  </div>
                </div>

                <div className="apple-google-imgs">
                      <DeviceDetect />
                </div>

                <div className="one-instruction">
                  <div className="one-instruction-img-container">
                    <img src={Bullet2} alt="bullet point 2" />
                  </div>
                  <div className="instruction-content">
                    <h6>Register</h6>
                    <p>Launch the app and follow the instructions.</p>
                  </div>
                </div>
                
                <div className="one-instruction">
                  <div className="one-instruction-img-container">
                      <img src={Bullet3} alt="bullet point 3" />
                  </div>
                  <div className="instruction-content">
                      <h6>Scan</h6>
                      <label className="scan-qr-title">
                        YOUR UNIQUE QR CODE TO SCAN
                        <img src={images.bulletArrow} alt="yellow arrow pointing right" style={{marginLeft: "5px"}}/>
                      </label>
                      <div className="qr-code-container desktop-hidden">
                      {this.state.qrCodeExpired ? (
                        <div className="expired-qr" style={{ height: qrSize, width: qrSize }}>
                          <div className="expired-qr-text">
                            <h1>QR CODE EXPIRED</h1>
                            <h2 id="expired-qr-h2" onClick={this.getNewQRCode}>
                              Refresh Code
                            </h2>
                          </div>
                        </div>
                      ) : this.state.qrCode !== null ? (
                        <QRCodeComponent data={this.state.qrCode} size={qrSize}/>
                      ) : (
                        <div
                          style={{
                            background: Themes.currentTheme().colors.lighterMediumBase,
                            height: qrSize,
                            width: qrSize
                          }}></div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="one-instruction">
                  <div className="instruction-content">
                      <br></br>
                    <p>{Themes.currentTheme().strings.login_prompt_text}</p>
                    <br></br>
                    <br></br>
                    <div className="help-contact-and-terms">
                      <p>Issues with registering or authenticating? </p>
                      <ContactInfoText/>
                      <br></br>
                      <p>By registering your device, you are accepting our</p>
                        <p
                          className="text-link"
                          onClick={() => {
                            this.props.history.push({
                              pathname: "/terms"
                            })
                          }}>
                          {`End User License Agreement and Terms & Conditions`}
                        </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="qr-code-container mobile-hidden">
                {this.state.qrCodeExpired ? (
                  <div className="expired-qr" style={{ height: qrSize, width: qrSize }}>
                    <div className="expired-qr-text">
                      <h1>QR CODE EXPIRED</h1>
                      <h2 className="text-link" onClick={this.getNewQRCode}>
                        Refresh Code
                      </h2>
                    </div>
                  </div>
                ) : this.state.qrCode !== null ? (
                  <QRCodeComponent data={this.state.qrCode} size={qrSize}/>
                ) : (
                  <div
                    style={{
                      background: Themes.currentTheme().colors.lighterMediumBase,
                      height: qrSize,
                      width: qrSize
                    }}></div>
                )}
                <div className="qr-code-refresh-instructions">
                  <p>QR code not working?</p>
                  <p className="text-link" onClick={this.getNewQRCode}>
                    Refresh code
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withPermissions(Register, [Constants.PERMISSION_TYPES.AUTHENTICATED])
