import React, { Component } from "react"
import OmniAural from "omniaural"
import { Buffer } from "buffer"
import { PollingManager } from "../managers/PollingManager"
import { getPFAuthURL } from "../services"
import "./Challenge.css"
import { QRCodeComponent } from "../components/QRCodeComponent"
import { ChallengeHeader } from "./screen-components/ChallengeHeader"
import { ContactInfoText } from "../components/ContactInfoText"

const { Constants } = require("../Constants.js")

class ChallengePFA extends Component {
  constructor() {
    super()
    this.state = {
      defaultTitle: "block",
      defaultSubtitle: "block",
      timedOutSubtitle: "none",
      timedOutButton: "none",
      loginQRdata: null,
      qrContainer: "flex",
      deviceType: "desktop",
      QRCodeDisplay: "none"
    }

    OmniAural.register(this, "config")
  }

  componentDidMount() {
    this.getQRData()
    let isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent
      ) || isIpad
    ) {
      this.setState({ deviceType: "mobile" });
    } else {
      this.setState({ deviceType: "desktop" });
    }
  }

  getQRData = () => {
    return getPFAuthURL().then((resp) => {
      const { url, uuid } = resp

      let assertionParams = {}
      if (window.location.search) {
        const params = new URLSearchParams(window.location.search)
        const acs_relay = params.get("acs_relay")
        if (acs_relay) {
          assertionParams["acs_relay"] = encodeURIComponent(acs_relay)
        }
      }

      if (uuid) {
        assertionParams["uuid"] = uuid
      }

      this.setState({ loginQRdata: url, timedOutSubtitle: "none", timedOutButton: "none", qrContainer: "flex" }, () => {
        PollingManager.startPFAssertionPolling(
          assertionParams,
          this.assertionSuccess,
          this.assertionError
        )
      })
    })
  }

  assertionSuccess = (response) => {
    if (Constants.IS_DEV && response.acs_params?.uid) {
      this.props.cookies.set(
        Constants.COOKIE_ID,
        Buffer.from(response.acs_params.uid).toString("base64"),
        {
          expirces: 3600 * 2 // Two hours
        }
      )
    }

    this.setState({
      defaultTitle: "none",
      defaultSubtitle: "none"
    })

    this.props.history.push({
      pathname: "/process_assertion",
      state: {
        acs_url: response.acs_url,
        acs_params: response.acs_params,
        forwardPath: this.props.history.location?.state?.forwardPath
      }
    })
  }

  assertionError = (error) => {
    if (error.code === Constants.ERROR_CODES.POLLING_TIMEOUT) {
      this.setState({
        loginQRdata: null,
        qrContainer: "none",
        timedOutSubtitle: "block",
        timedOutButton: "block"
      })
    }
  }

  openMobileConnect = async () => {
    if (!this.state.loginQRdata) {
      await this.getQRData()
    }

    window.location.assign(this.state.loginQRdata)
  }

  render() {
    const { qr_code_size } = this.state.config

    return (
      <div>
        <div className="challenge-container">
          <ChallengeHeader />
          <div className="body-container">
            <div className="challenge-body-outer">
              <div className="challenge-body-inner">
                <div onClick={this.openMobileConnect} className="login-qr-container" style={{ display: this.state.qrContainer }}>

                  {
                    this.state.loginQRdata ?
                      this.state.deviceType === "mobile" ?
                        <div className="login-button-qr">
                          <div>
                            <div id="login-qr-container-instructions">
                              Tap to authenticate if you are on a device that has MobileConnect installed
                            </div>
                            <button type="button" className="register-button" onClick={this.openMobileConnect}>
                              <span>Authenticate</span>
                            </button>
                          </div>
                          <div>
                            <button className="register-button" onClick={() => {
                              let display = ""
                              if (this.state.QRCodeDisplay === "none") {
                                display = "flex"
                              }
                              else {
                                display = "none"
                              }
                              this.setState({ QRCodeDisplay: display })
                            }}>Use QR Code</button>
                            <div className="login-qr-container" style={{ display: this.state.QRCodeDisplay }}>
                              <span id="login-qr-container-instructions">
                                Scan the QR code below with MobileConnect to log in.
                              </span>
                              <div onClick={this.openMobileConnect}>
                                <QRCodeComponent data={this.state.loginQRdata} size={qr_code_size} />
                              </div>
                            </div>
                          </div>
                        </ div>
                        :
                        <div className="login-qr-container">
                          <span id="login-qr-container-instructions">
                            Scan the QR code below with MobileConnect to log in.
                          </span>
                          <div onClick={this.openMobileConnect}>
                            <QRCodeComponent data={this.state.loginQRdata} size={qr_code_size} />
                          </div>
                        </div>
                      :
                      null
                  }
                  <br />
                  <p style={{ alignSelf: "center" }}>
                    {`Not Registered? `}<a href="/register_pfa">Register Here</a>
                  </p>
                  <br />
                </div>
                <br />
                <p id="timeout-message" style={{ display: this.state.timedOutSubtitle }}>
                  Code has expired.
                </p>
                <br />
                <div className="return-to-login" style={{ display: this.state.timedOutButton }}>
                  <button onClick={this.getQRData} className="challenge-button return-to-login">
                    REFRESH QR CODE
                  </button>
                </div>
                <p>Need assistance?</p>
                <ContactInfoText />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ChallengePFA
