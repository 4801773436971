import { Themes } from "../../managers/ThemeManager"
import "./BackArrowButton.css"

export const BackArrowButton = () => {
  return (
    <div onClick={() => window.history.back()} className="arrow-back-button">
      <img src={Themes.currentTheme().images.backArrow} />
    </div>
  )
}
