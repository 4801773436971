import { QRCodeSVG } from "qrcode.react"
import { Themes } from "../managers/ThemeManager"
import { Constants } from "../Constants"

export const QRCodeComponent = ({ data, size }) => {
  return (
    <QRCodeSVG
      value={data}
      size={size || Constants.DEFAULT_QR_SIZE}
      level={"L"}
      bgColor={Themes.currentTheme().colors.qrBackground}
      fgColor={Themes.currentTheme().colors.qrForeground}
      imageSettings={{
        src: Themes.currentTheme().images.appIcon,
        height: 50,
        width: 50,
        excavate: true
      }}
    />
  )
}
