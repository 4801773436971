import React from "react"
import OmniAural from "omniaural"
import { Themes } from "../../managers/ThemeManager"

export const ChallengeHeader = () => {
  const config = OmniAural.state.config.value()
  const showTestBanner = config.site_type && config.site_type !== "production"
  const images = Themes.currentTheme().images
  return (
    <div className="challenge-header">
      {showTestBanner && (
        <img
          src={require("../../assets/Test-Banner.png")}
          className="challenge-header-test-banner"
        />
      )}
      <img
        src={images.mobileConnectLogo}
        className="challenge-header-image"
        alt="mobile connect logo"
      />
    </div>
  )
}
