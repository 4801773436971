import { getRegistrationStatus, getAssertion, getPFRegistrationStatus } from "../services"
import OmniAural from "omniaural"
import { Constants } from "../Constants"

const validateResponse = (response) => {
  return `${response.code}` === "1200"
}

const poll = ({ apiCall }) => {
  const maxTries = OmniAural.state.config.get_assertion_retry_limit.value() || 60
  const interval = (OmniAural.state.config.get_assertion_retry_interval.value() || 2) * 1000
  let totalTries = 0
  let timeoutId = null

  const executePoll = async (resolve, reject) => {
    const response = await apiCall()
    totalTries += 1
    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    if (validateResponse(response)) {
      resolve(response)
    } else if (totalTries > maxTries) {
      let e = new Error("Max attempts exceeded. API call timed out")
      e.code = Constants.ERROR_CODES.POLLING_TIMEOUT
      reject(e)
    } else if (`${response.code}` !== Constants.ERROR_CODES.AWAITING_MOBILE_RESPONSE) {
      let e = new Error("Bad response code")
      e.code = response.code
      reject(e)
    } else {
      timeoutId = setTimeout(executePoll, interval, resolve, reject)
    }
  }

  return new Promise(executePoll)
}

// Add Polling methods needed here.
export const PollingManager = {
  startAssertionPolling: (uid, params, onCompletion, onError) => {
    poll({
      apiCall: () => getAssertion(uid, params)
    })
      .then(onCompletion)
      .catch(onError)
  },

  startRegistrationStatusPolling: (uid, onCompletion, onError) => {
    poll({
      apiCall: () => getRegistrationStatus(uid)
    })
      .then(onCompletion)
      .catch(onError)
  },
  startPFRegistrationStatusPolling: (params, onCompletion, onError) => {
    poll({
      apiCall: () => getPFRegistrationStatus(params.uid, params.uuid)
    })
      .then(onCompletion)
      .catch(onError)
  },

  startPFAssertionPolling: (params, onCompletion, onError) => {
    poll({
      apiCall: () => getAssertion("", params)
    })
      .then(onCompletion)
      .catch(onError)
  }
}
