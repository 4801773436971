import { useEffect, useRef } from "react"

export function UseRawHTMLContent({ html, dangerouslySetInnerHTML, ...rest }) {
  // We remove 'dangerouslySetInnerHTML' from props passed to the div
  const divRef = useRef(null)

  useEffect(() => {
    if (!html || !divRef.current) throw new Error("html prop cant't be null")

    const slotHtml = document.createRange().createContextualFragment(html)
    divRef.current.appendChild(slotHtml)
  }, [html, divRef])

  return <div {...rest} ref={divRef}></div>
}

export default UseRawHTMLContent
