export const Themes = {
  themeKey: "army", // Defaults to army but is set by getConfig
  army: {
    images: {
      appIcon: require("../assets/ARAppIcon.png"),
      loginBackground1: require("../assets/1585_funeral.jpeg"),
      loginBackground2: require("../assets/1585_howitzer.jpeg"),
      loginBackground3: require("../assets/1585_ny_chopper.jpeg"),
      loginBackground4: require("../assets/1585_soldier_flag.jpeg"),
      loginBackground5: require("../assets/1585_targetshoot.jpeg"),
      aesdImage: require("../assets/1585_AESD_Logo_2020.png"),
      eamsImage: require("../assets/1585_eams-a_logov3.png"),
      appHeroImage: require("../assets/App-Hero-Army.png"),
      mobileConnectLogo: require("../assets/AA-logo-horizontal.png"),
      headerBackground: require("../assets/HeaderBackgroundTexture.png"),
      bellIcon: require("../assets/bell-icon.png"),
      banner: require("../assets/banner.png"),
      bulletArrow: require("../assets/armyArrow.svg").default, //using default because this is an svg file
      backArrow: require("../assets/Arrow_left.svg").default //using default because this is an svg file
    },
    colors: {
      darkestBase: "#000000",
      darkestMediumBase: "#444444",
      darkMediumBase: "#666666",
      mediumBase: "#808080",
      lightMediumBase: "#D3D3D3",
      lighterMediumBase: "#DCDCDC",
      lightestMediumBase: "#F6F6F6",
      lightestBase: "#FFFFFF",
      accent1: "#FEC325",
      accent2: "#BEB8A8",
      accent3: "#CDE1D2",
      error: "#DC143C",
      good: "#008000",
      warning: "#B8860B",
      buttonText: "#000000",
      qrBackground: "#fff",
      qrForeground: "#3f4239"
    },
    strings: {
      appName: "Army MobileConnect app",
      enterpriseAbbrev: "EAMS-A",
      registration_greeting:
        "Instant, secure logins to EAMS-A using an app on your personal mobile device.",
      login_prompt_text: `That's it. Now just log in to EAMS-A with your username and password.  Each time you log in, you will receive a notification on your device  asking you to approve or deny the login attempt.`,
      register_instructions_bullet_01: `Adds security to username/password logins.`,
      register_instructions_bullet_02: `Works anywhere, with or without internet connectivity on your device.`,
      register_instructions_bullet_03: "No need for a CAC.",
      register_instructions_bullet_04: `Protects your account from malicious activity.`,
      register_success_message_mfa: `The next time you log in to EAMS-A without your CAC, you will be asked to approve or deny the login attempt on your registered device.`,
      register_success_message_pfa:
        "The next time you access EAMS-A without your CAC, you can use your registered device to scan a QR code to login.",
      registered_faq_title: "SUPPORT FAQ",
      registered_faq_subtitle: "Answers to common questions about the #app-name and service.",
      service_question_01_question: `I no longer have access to the device I registered with. What should I do?`,
      service_question_01_answer: `You will need to contact #contact-info and ask
      for them to deregister your device. Once you have been deregistered, you can register
      again with a different device.`,
      service_question_02_question: `Do I need connectivity (cell service or wi-fi) on my device to authenticate?`,
      service_question_02_answer: `No. For times when you do not have connectivity, the Army MobileConnect app offers one-time passcodes instead of approving an authentication request. Simply follow the prompts on the EAMS-A login page to enter a one-time passcode. You can find your passcode on the home screen of your Army MobileConnect app. Keep in mind, however, for security we only allow three consecutive logins using one-time passcodes. After that, you will be required to respond to an authentication request (notification), which does require connectivity to receive.`,
      service_question_03_question: `I don’t want to continue using the Army MobileConnect app. How can I stop?`,
      service_question_03_answer: `You are free to deregister your account and device at any time. Click the “Deregister my current device” button above. Once deregistered, you can resume logging into as you had prior to registering. You can also uninstall the app from your personal device at that time.`,
      termsOfUseAgreement: `Licensed to: United States Army (the “Licensee”)
      Please read this Terms of Use agreement carefully before downloading and using the “Army MobileConnect”
      application (the “Application"). By downloading, accessing and/or using this mobile application owned by
      HQDA CIO/G6. You signify that you have read, understand and agree to be bound by all of the terms and
      conditions as set forth in this Terms of Use Agreement (“Agreement”), Privacy Policy. HQDA CIO/G6 may
      change the terms of the Agreement at any time and without notice, effective upon the posting of the
      revised Agreement. Your continued use of the Application shall be considered your acceptance of the
      revised Agreement. You must have the most current version of the Application to ensure that it is
      working properly. It is your responsibility to periodically check the Application at the corresponding
      application store (a.k.a. application marketplace), to determine if you have the most current version of
      the Application. The Application may contain links to third party websites that are not owned or
      controlled by HQDA CIO/G6 or the Licensee. HQDA CIO/G6 has no command or control over, and accepts no
      obligation & accountability for, the content, intellectual property, privacy policies, or practices of
      any third party websites. In addition, HQDA CIO/G6 will not and cannot censor or edit the content of any
      third-party site. By using the Application, you explicitly relieve HQDA CIO/G6 from any and all
      liability arising from your use of any third-party website. Accordingly, we encourage you to read the
      terms and conditions and privacy policy of all websites that you visit through this application. If you
      do not agree to this Agreement, please do not download or use the Application.
      1. Confirmation of your age; Ability to Accept Terms of Use:
      You affirm that you are either more than 18 years of age, or an emancipated minor, or possess legal
      parental or guardian consent, and are fully able and competent to enter into the terms, conditions,
      obligations, affirmations, representations, and warranties set forth in these Terms of Use, and to abide
      by and comply with these Terms of Use.
      2. Mobile Device Access:
      In order to download, install and use the Application, you will have to be logged in on your mobile
      device (“Device”). You may never use another user’s device without permission. You are solely
      responsible for the activity that occurs on your device, and you must keep your device password secure.
      You must notify your security team (or Licensee) immediately of any breach of security or unauthorized
      use of your device. Although, HQDA CIO/G6 will not be liable for your losses caused by any unauthorized
      use of your device, you may be liable for the losses of HQDA CIO/G6 or the Licensee due to such
      unauthorized use.
      3. No Warranties:
      The Application is provided subject to all of the terms set forth in the service level agreement (SLA)
      with the Licensee. HQDA CIO/G6 does not warrant that: (i) its Application will be constantly available,
      or available at all; (ii) that the information in this Application is complete, true, accurate, or (iii)
      your opt-out choices will be successfully executed in all cases. HQDA CIO/G6 does not warrant that the
      Application is free of defects or errors.
      4. Use and Prohibited Use Restrictions:
      You may use the Application only for your own personal, non- commercial use. You are strictly prohibited
      from, and agree that you will not, adapt, edit, change, modify, transform, publish, republish,
      distribute, or redistribute this Application or the material on this Application (in any form or media)
      without HQDA CIO/G6's prior written consent. You agree not to use any automated data collection methods,
      data mining, robots, or scraping or any data gathering methods of any kind on this Application. During
      your use of the Application, you will comply with all applicable laws. HQDA CIO/G6 reserves the right to
      include or discontinue any feature and/or aspect of the Application at any time.
      5. Limitations of Liability and Indemnification:
      The downloading and/or use of the application is entirely at your own risk and in no event shall pn
      automation be liable (whether under the law of contracts, torts or otherwise) for any direct, indirect,
      incidental, consequential, special, exemplary, punitive or any other monetary or other damages, fees,
      fines, penalties or liabilities (collectively “damages”) whatsoever resulting from any (i) errors,
      mistakes, or inaccuracies of content, (ii) personal injury or property damage, of any nature whatsoever,
      resulting from your access to and use of our application, (iii) any unauthorized access to or use of our
      secure servers and/or any and all personal information and/or financial information stored therein, (iv)
      any interruption or cessation of transmission to or from our application, (iv) any bugs, viruses, trojan
      horses, or the like, which may be transmitted to or through our application by any third party, and/or
      (v) any errors or omissions in any content or for any loss or damage of any kind incurred as a result of
      your use of any content posted, emailed, transmitted, or otherwise made available via the application,
      whether based on warranty, contract, tort, or any other legal theory, and whether or not the company is
      advised of the possibility of such damages. The foregoing limitation of liability shall apply to the
      fullest extent permitted by law in the applicable jurisdiction. Your sole and exclusive remedy for
      dissatisfaction with the application is to stop using the application. You agree to defend, indemnify
      and hold HQDA CIO/G6, and its officers, directors, employees, representatives and agents harmless from
      and against any claims, actions, demands, liabilities, judgments, and settlements, including without
      limitation, reasonable legal fees resulting from or alleged to result from your use of this application.
      The Application development life cycle is controlled and offered by HQDA CIO/G6 from its facilities in
      the United States of America. HQDA CIO/G6 makes no representations that the Application is appropriate
      or available for use in other locations. Those who access or use the Application from other
      jurisdictions do so at their own preference and are responsible for compliance with local law.
      6. Unenforceable Provisions:
      If any provision of this Agreement is, or is found to be unenforceable under applicable law, that will
      not affect the enforceability of the other provisions of this Agreement.
      7. Ownership of Intellectual Property and Limited License:
      All intellectual property to this Application, including all Application material, is protected by
      copyright, trademark, or patent laws, and is owned exclusively by HQDA CIO/G6, its suppliers or the
      Licensee to whom the Application has been licensed. Intellectual property, includes, but is not limited
      to, computer or software code, scripts, design elements, graphics, interactive features, artwork, text
      communication, and any other content that may be found on or in the Application. All trademarks, service
      marks and trade names are owned, registered and/or licensed by HQDA CIO/G6. HQDA CIO/G6 grants to you a
      worldwide, non-exclusive, royalty-free, revocable license to: download this Application to a mobile
      device; use this Application as herein set forth; copy and store this Application and the material on
      this Application on your mobile device’s cache memory; and print pages from this Application for your
      own personal and non-commercial use. HQDA CIO/G6 does not grant you any other rights whatsoever in
      relation to this Application or the material on this Application. All other rights are expressly
      reserved by HQDA CIO/G6.
      8. Enforcement of Copyright and Protection of Intellectual Property:
      If HQDA CIO/G6 discovers that you have used its copyrighted or other protected materials in
      contravention of the terms of the license above, HQDA CIO/G6 may bring legal proceedings against you
      seeking monetary damages and an injunction against you. You could also be ordered to pay legal fees and
      costs. If you become aware of any use of HQDA CIO/G6's copyright or protected materials that contravenes
      or may contravene the terms of the license above, immediately report this to HQDA CIO/G6 care of Legal
      Department. Our mailing address can be found on our web site https://www.army.mil/ciog6/
      9. Governing Law and Dispute Resolution:
      This Agreement shall be governed by and shall be construed in accordance with the laws of the State of
      HQDA CIO/G6, excluding its choice of law rules. All disputes relating to this Agreement shall be settled
      in the courts located within the county of HQDA CIO/G6, in HQDA CIO/G6 State. The parties submit to
      personal jurisdiction within the State of HQDA CIO/G6.
      10. Contact Information:
      HQDA CIO/G6 may be contacted by mail at our mailing address (Our mailing address can be found on our web
      site https://www.army.mil/ciog6. Our phone number is 1.866.335-ARMY.`,
      privacyPolicy: `YOU ARE ACCESSING A U.S. GOVERNMENT (USG) INFORMATION SYSTEM (IS) THAT IS PROVIDED FOR USG-AUTHORIZED
      USE ONLY.

      By using this IS (which includes any device attached to this IS), you consent to the following
      conditions:
      The USG routinely intercepts and monitors communications on this IS for purposes including, but not
      limited to, penetration testing, COMSEC monitoring, network operations and defense, personnel misconduct
      (PM), law enforcement (LE), and counterintelligence (CI) investigations.

      At any time, the USG may inspect and seize data stored on this IS.

      This IS includes security measures (e.g., authentication and access controls) to protect USG
      interests--not for your personal benefit or privacy.

      Notwithstanding the above, using this IS does not constitute consent to PM, LE or CI investigative
      searching or monitoring of the content of privileged communications, or work product, related to
      personal representation or services by attorneys, psychotherapists, or clergy, and their assistants.
      Such communications and work product are private and confidential. See User Agreement for details.`,
      challenge_default_subtitle: "If the notification disappears before you can take action, open the Army MobileConnect app",
      not_responded_msg: "If you still haven't received a notification, open the Army MobileConnect app",
      not_responded_sms_msg: "Check your text messages for the verification code"

    },
    faq_items:[
      {
        question: `What is multi-factor authentication?`, 
        answer: `Multi-factor authentication refers to an additional validation method on an account in addition to a primary factor which is typically a password. It greatly reduces the ability of your account to be “hacked” if your password is leaked or guessed.`, 
        image: null
      }, 
      {
        question: `How does Army MobileConnect multi-factor authentication work?`, 
        answer: `There are two ways multi-factor verification works with Army MobileConnect.\n 
        The main method is through a push notification that is sent to your devices when you (or malicious actor) attempt to log into your EAMS-A account. When the device receives the push notification you’ll have the option to approve or deny it. If you approve it, you’ll be logged into your account. If you deny it, the login will be blocked and you’ll be prompted for a reason why you denied the login. \n
        The second method is with a temporary one time password (TOTP) in the form of a 6 digit code. When you attempt to log into your EAMS-A account, you’ll have the option to type this code into the login form which will allow you access to your account.`, 
        image: null
      },
      {
        question: `What does registering for multi-factor authentication get me?`, 
        answer: `Currently, added security that your account won’t be used by anyone but yourself. In the future, you’ll be able to access more secure content using Army MobileConnect than you would by just logging in with username/password alone.`, 
        image: null
      },
      {
        question: `How do I register?`, 
        answer: `• Download the Army MobileConnect app from the Apple or Google app stores (depending on your device).
        • Open the app and click Register, this will bring you to an instructions page with a link ( https://mobileconnect.us.army.mil ) to put into a web browser on a desktop or laptop computer.
        • Follow the instructions on your computer.`, 
        image: null
      },
      {
        question: `Do I need a CAC to register or use Army MobileConnect?`, 
        answer: `No. You only need your EAMS-A username and password. However, if you do have a CAC, you may also register while logged in with your CAC by going to the same registration page referenced in the app.`, 
        image: null
      },
      {
        question: `I’m getting an error during registration…`, 
        answer: `There are a number of reasons registration can fail, most are easy to solve.
        • Invalid username/password : Go to the ICAM Portal ( https://icamportal.us.army.mil/ ) and reset your password.
        • Invalid QR data or expired data : The QR code you scan during registration is only valid for a limited time, simply refresh the registration page and start the registration process again. 
        • Unable to complete registration : To register you just have a network connection and you must allow push notifications for the app. If either of these things are not available to the app, registration will fail. 
        • I said “no” to push notifications : Go into the Settings on the device, scroll down to the Notifications option and tap into it. Scroll to the Army MobileConnect app and tap into it. Turn on Allow Notifications. Restart the application and registration process. 
        • I said “no” to camera access: Go into the Settings on the device, scroll down to Army MobileConnect and tap into it. Turn on Camera access. Restart the app and restart the registration process. `, 
        image: null
      },
      {
        question: `I do not know my EAMS-A account username/password, how do I get that?`, 
        answer: `Go to the ICAM Portal ( https://icamportal.us.army.mil/ ).`, 
        image: null
      },
      {
        question: `I’ve registered, but I no longer want to have multi-factor authentication on my account. Can I remove it?`, 
        answer: `To deregister - please go to https://mobileconnect.us.army.mil , login and click deregister my device. `, 
        image: null
      },
      {
        question: `How many devices can I register?`, 
        answer: `Currently a user may only have one device registered at a time. To move your registration from one device to another, you must first deregister your current device and then follow the normal steps for registering the new device.`, 
        image: null
      },
      {
        question: `How many users can use a single mobile device?`, 
        answer: `Only one user per device. It is not currently recommended you use a shared device for multi-factor authentication. However, multi-user support is a feature that will be added in a future version of the application.`, 
        image: null
      },
      {
        question: `What is the 6-digit code for when I open the app?`, 
        answer: `This is called a Temporary One Time Password (TOTP) and is used to validate your username and password if you are not able to receive push notifications for any reason. It can usually be ignored if you approve your multi-factor authentication through push notifications.`, 
        image: null
      },
      {
        question: `I’ve received a multi-factor authentication request but did not try to log in, what should I do?`, 
        answer: `If you did not initiate a login but are receiving a request to verify a login, then there is a malicious login attempt on your account. Deny the request and provide the appropriate reason why. Then change your EAMS-A password by going to the ICAM Portal ( https://icamportal.us.army.mil/ ). 
        Note that your account was not compromised as long as you didn’t approve the request.`, 
        image: null
      },
      {
        question: `I’ve received a multi-factor authentication request but did not log in and I approved it - what should I do?`, 
        answer: `Someone has access to your account. All sessions should be killed and the password should be invalidated. Change your EAMS-A password at the ICAM Portal ( https://icamportal.us.army.mil/ ).`, 
        image: null
      },
      {
        question: `Can I use the MobileConnect app with other applications?`, 
        answer: `The Army MobileConnect app currently only works with your EAMS-A account and secures any application that uses EAMS-A username and passwords. It does not currently work for any other platform.`, 
        image: null
      },
      {
        question: `I’m getting an error that I can no longer use temporary passwords, why?`, 
        answer: `Using the 6 digit temporary password is a backup for when you are unable to receive push notifications due to being out of range of a wireless network. However prolonged use of the TOTP means we’re unable to verify the device is still in your possession. You’ll need to log in via standard, networked, push notifications every few logins to allow TOTPs to continue working between non-network logged in.`, 
        image: null
      },
      {
        question: `Why am I not seeing the “MobileConnect” button for a site that I’m trying to login to?`, 
        answer: `Site Owners will need to enable primary factor on their SSO property in the GCDS portal.  Contact the site owner/administrator to get the feature enabled.`, 
        image: null
      },
      {
        question: `How can I tell which version of MobileConnect app that I have on my personal device?`, 
        answer: `Click Settings and you should be able to see the version at the bottom of the app.`, 
        image: null
      },
      {
        question: `Can I still use MFA (TOTP or push notification) if I enroll in primary factor?`, 
        answer: `Yes, the MobileConnect app will support all methods.`, 
        image: null
      },
  ]

    ,
  },
  airforce: {
    images: {
      appIcon: require("../assets/AFAppIcon.png"),
      loginBackground1: require("../assets/1585_funeral.jpeg"),
      loginBackground2: require("../assets/1585_howitzer.jpeg"),
      loginBackground3: require("../assets/1585_ny_chopper.jpeg"),
      loginBackground4: require("../assets/1585_soldier_flag.jpeg"),
      loginBackground5: require("../assets/1585_targetshoot.jpeg"),
      aesdImage: require("../assets/1585_AESD_Logo_2020.png"),
      eamsImage: require("../assets/1585_eams-a_logov3.png"),
      appHeroImage: require("../assets/App-Hero-AF.png"),
      mobileConnectLogo: require("../assets/AFMobileConnect-logo.png"),
      banner: require("../assets/AFbanner.png"),
      headerBackground: require("../assets/AFHeaderBackgroundTexture.png"),
      bellIcon: require("../assets/bell-icon-white.png"),
      bulletArrow: require("../assets/AFArrow.svg").default, //using default because this is an svg file
      backArrow: require("../assets/AFArrow_left.svg").default //using default because this is an svg file
    },
    colors: {
      darkestBase: "#0f1727",
      darkestMediumBase: "#444444",
      darkMediumBase: "#666666",
      mediumBase: "#808080",
      lightMediumBase: "#D3D3D3",
      lighterMediumBase: "#DCDCDC",
      lightestMediumBase: "#F6F6F6",
      lightestBase: "#FFFFFF",
      accent1: "#105290",
      accent2: "#D3D3D3",
      accent3: "#CDE1D2",
      error: "#DC143C",
      good: "#008000",
      warning: "#B8860B",
      buttonText: "#FFFFFF",
      qrBackground: "#fff",
      qrForeground: "#105290"
    },
    strings: {
      appName: "Air Force MobileConnect app",
      registration_greeting: "Instant, secure logins using an app on your personal mobile device.",
      enterpriseAbbrev: "Cloud One",
      login_prompt_text: "",
      successfull_registration_banner_description:
        "You may now access secure content with your mobile device.",
      register_instructions_bullet_01: "",
      register_instructions_bullet_02: "",
      register_instructions_bullet_03: "No need for a CAC.",
      register_instructions_bullet_04: "Access content directly on your device.",
      register_success_message: "",
      registered_faq_title: "Answers to common questions about the #app-name and service can be found by clicking the link below.",
      registered_faq_subtitle: "",
      service_question_01_question: "",
      service_question_01_answer: "",
      service_question_02_question: "",
      service_question_02_answer: "",
      service_question_03_question: "",
      service_question_03_answer: "",
      termsOfUseAgreement: `Licensed to: United States Air Force (the “Licensee”)
      Please read this Terms of Use agreement carefully before downloading and using the “Air Force MobileConnect”
      application (the “Application"). By downloading, accessing and/or using this mobile application owned by
      AFLCMC\\HNII. You signify that you have read, understand and agree to be bound by all of the terms and
      conditions as set forth in this Terms of Use Agreement (“Agreement”), Privacy Policy. AFLCMC\\HNII may
      change the terms of the Agreement at any time and without notice, effective upon the posting of the
      revised Agreement. Your continued use of the Application shall be considered your acceptance of the
      revised Agreement. You must have the most current version of the Application to ensure that it is
      working properly. It is your responsibility to periodically check the Application at the corresponding
      application store (a.k.a. application marketplace), to determine if you have the most current version of
      the Application. The Application may contain links to third party websites that are not owned or
      controlled by AFLCMC\\HNII or the Licensee. AFLCMC\\HNII has no command or control over, and accepts no
      obligation & accountability for, the content, intellectual property, privacy policies, or practices of
      any third party websites. In addition, AFLCMC\\HNII will not and cannot censor or edit the content of any
      third-party site. By using the Application, you explicitly relieve AFLCMC\\HNII from any and all
      liability arising from your use of any third-party website. Accordingly, we encourage you to read the
      terms and conditions and privacy policy of all websites that you visit through this application. If you
      do not agree to this Agreement, please do not download or use the Application.
      1. Confirmation of your age; Ability to Accept Terms of Use:
      You affirm that you are either more than 18 years of age, or an emancipated minor, or possess legal
      parental or guardian consent, and are fully able and competent to enter into the terms, conditions,
      obligations, affirmations, representations, and warranties set forth in these Terms of Use, and to abide
      by and comply with these Terms of Use.
      2. Mobile Device Access:
      In order to download, install and use the Application, you will have to be logged in on your mobile
      device (“Device”). You may never use another user’s device without permission. You are solely
      responsible for the activity that occurs on your device, and you must keep your device password secure.
      You must notify your security team (or Licensee) immediately of any breach of security or unauthorized
      use of your device. Although, AFLCMC\\HNII will not be liable for your losses caused by any unauthorized
      use of your device, you may be liable for the losses of AFLCMC\\HNII or the Licensee due to such
      unauthorized use.
      3. No Warranties:
      The Application is provided subject to all of the terms set forth in the service level agreement (SLA)
      with the Licensee. AFLCMC\\HNII does not warrant that: (i) its Application will be constantly available,
      or available at all; (ii) that the information in this Application is complete, true, accurate, or (iii)
      your opt-out choices will be successfully executed in all cases. AFLCMC\\HNII does not warrant that the
      Application is free of defects or errors.
      4. Use and Prohibited Use Restrictions:
      You may use the Application only for your own personal, non- commercial use. You are strictly prohibited
      from, and agree that you will not, adapt, edit, change, modify, transform, publish, republish,
      distribute, or redistribute this Application or the material on this Application (in any form or media)
      without AFLCMC\\HNII's prior written consent. You agree not to use any automated data collection methods,
      data mining, robots, or scraping or any data gathering methods of any kind on this Application. During
      your use of the Application, you will comply with all applicable laws. AFLCMC\\HNII reserves the right to
      include or discontinue any feature and/or aspect of the Application at any time.
      5. Limitations of Liability and Indemnification:
      The downloading and/or use of the application is entirely at your own risk and in no event shall pn
      automation be liable (whether under the law of contracts, torts or otherwise) for any direct, indirect,
      incidental, consequential, special, exemplary, punitive or any other monetary or other damages, fees,
      fines, penalties or liabilities (collectively “damages”) whatsoever resulting from any (i) errors,
      mistakes, or inaccuracies of content, (ii) personal injury or property damage, of any nature whatsoever,
      resulting from your access to and use of our application, (iii) any unauthorized access to or use of our
      secure servers and/or any and all personal information and/or financial information stored therein, (iv)
      any interruption or cessation of transmission to or from our application, (iv) any bugs, viruses, trojan
      horses, or the like, which may be transmitted to or through our application by any third party, and/or
      (v) any errors or omissions in any content or for any loss or damage of any kind incurred as a result of
      your use of any content posted, emailed, transmitted, or otherwise made available via the application,
      whether based on warranty, contract, tort, or any other legal theory, and whether or not the company is
      advised of the possibility of such damages. The foregoing limitation of liability shall apply to the
      fullest extent permitted by law in the applicable jurisdiction. Your sole and exclusive remedy for
      dissatisfaction with the application is to stop using the application. You agree to defend, indemnify
      and hold AFLCMC\\HNII, and its officers, directors, employees, representatives and agents harmless from
      and against any claims, actions, demands, liabilities, judgments, and settlements, including without
      limitation, reasonable legal fees resulting from or alleged to result from your use of this application.
      The Application development life cycle is controlled and offered by AFLCMC\\HNII from its facilities in
      the United States of America. AFLCMC\\HNII makes no representations that the Application is appropriate
      or available for use in other locations. Those who access or use the Application from other
      jurisdictions do so at their own preference and are responsible for compliance with local law.
      6. Unenforceable Provisions:
      If any provision of this Agreement is, or is found to be unenforceable under applicable law, that will
      not affect the enforceability of the other provisions of this Agreement.
      7. Ownership of Intellectual Property and Limited License:
      All intellectual property to this Application, including all Application material, is protected by
      copyright, trademark, or patent laws, and is owned exclusively by AFLCMC\\HNII, its suppliers or the
      Licensee to whom the Application has been licensed. Intellectual property, includes, but is not limited
      to, computer or software code, scripts, design elements, graphics, interactive features, artwork, text
      communication, and any other content that may be found on or in the Application. All trademarks, service
      marks and trade names are owned, registered and/or licensed by AFLCMC\\HNII. AFLCMC\\HNII grants to you a
      worldwide, non-exclusive, royalty-free, revocable license to: download this Application to a mobile
      device; use this Application as herein set forth; copy and store this Application and the material on
      this Application on your mobile device’s cache memory; and print pages from this Application for your
      own personal and non-commercial use. AFLCMC\\HNII does not grant you any other rights whatsoever in
      relation to this Application or the material on this Application. All other rights are expressly
      reserved by AFLCMC\\HNII.
      8. Enforcement of Copyright and Protection of Intellectual Property:
      If AFLCMC\\HNII discovers that you have used its copyrighted or other protected materials in
      contravention of the terms of the license above, AFLCMC\\HNII may bring legal proceedings against you
      seeking monetary damages and an injunction against you. You could also be ordered to pay legal fees and
      costs. If you become aware of any use of AFLCMC\\HNII's copyright or protected materials that contravenes
      or may contravene the terms of the license above, immediately report this to AFLCMC\\HNII care of Legal
      Department. Our mailing address can be found on our web site https://cloudone.af.mil/
      9. Governing Law and Dispute Resolution:
      This Agreement shall be governed by and shall be construed in accordance with the laws of the State of
      AFLCMC\\HNII, excluding its choice of law rules. All disputes relating to this Agreement shall be settled
      in the courts located within the county of AFLCMC\\HNII, in AFLCMC\\HNII State. The parties submit to
      personal jurisdiction within the State of AFLCMC\\HNII.
      10. Contact Information:
      AFLCMC\\HNII may be contacted by mail at our mailing address (Our mailing address can be found on our web
      site https://cloudone.af.mil/. Our phone number is 1.866.335-Air Force.`,
      privacyPolicy: `YOU ARE ACCESSING A U.S. GOVERNMENT (USG) INFORMATION SYSTEM (IS) THAT IS PROVIDED FOR USG-AUTHORIZED
      USE ONLY.

      By using this IS (which includes any device attached to this IS), you consent to the following
      conditions:
      The USG routinely intercepts and monitors communications on this IS for purposes including, but not
      limited to, penetration testing, COMSEC monitoring, network operations and defense, personnel misconduct
      (PM), law enforcement (LE), and counterintelligence (CI) investigations.

      At any time, the USG may inspect and seize data stored on this IS.

      This IS includes security measures (e.g., authentication and access controls) to protect USG
      interests--not for your personal benefit or privacy.

      Notwithstanding the above, using this IS does not constitute consent to PM, LE or CI investigative
      searching or monitoring of the content of privileged communications, or work product, related to
      personal representation or services by attorneys, psychotherapists, or clergy, and their assistants.
      Such communications and work product are private and confidential. See User Agreement for details.`,
      challenge_default_subtitle: "",
      not_responded_msg: "",
      not_responded_sms_msg: "Check your text messages for the verification code"
    },
    faq_items:[
    {
      question: "How do I download the MobileConnect AF application on my personal device?", 
      answer: "Mobileconnect AF is available on both the Apple and Google application stores.  Please be sure to use the search term “Mobileconnect AF” to quickly and easily locate the correct application.", 
      image: null
    }, 
    {
      question: "How does AF MobileConnect multi-factor authentication work?", 
      answer: "AF MobileConnect uses a phishing-resistant, QR-Code based multi-factor authentication approach.  Instead of logging in with a username / password or a CAC card, users are presented with a QR-code that they can scan from a registered mobile device.  After scanning the QR-Code, a user simply enters the PIN that they created at registration-time.", 
      image: null
    }, 
    {
      question: "What does registering for multi-factor authentication get me?", 
      answer: "Registering for AF Mobileconnect makes accessing supporting USAF Enterprise applications easier - without compromising on security.  For situations where a CAC reader is not practical, MobileConnect offers a streamlined access option that complies with USAF security requirements.", 
      image: null
    }, 
    {
      question: "How do I register?", 
      answer: "Download the AF MobileConnect app from the Apple or Google app stores (depending on your device). • Open the app and click Register, this will bring you to an instructions page with a link ( https://mobileconnect.cce.af.mil )  to put into a web browser on a desktop or laptop computer. • Follow the instructions on your computer.", 
      image: null
    }, 
    {
      question: "Do I need a CAC to register or use AF MobileConnect?", 
      answer: "Currently only Authorized CAC holders can register for MobileConnect.  A follow-on release will add support for sponsored Non-CAC users to register.  That feature will be available for use later in 2023.", 
      image: null
    }, 
    {
      question: "I am a Mission System Owner.  How do I enable AF MobileConnect authentication for my application?", 
      answer: "Mission system owners must request MobileConnect to be enabled for their application before users can use the mobile application.  All systems hosted in Cloud One, as well as all applications that leverage GCDS SSO as their authentication provider are eligible.   System owners for eligible applications may contact aflcmc.cce.helpdesk@us.af.mil in order to request MobileConnect AF enablement.", 
      image: null
    }, 
    {
      question: "I’ve registered, but I no longer want to have multi-factor authentication on my account. Can I remove it?", 
      answer: "To deregister - please go to https://mobileconnect.cce.af.mil , login and click deregister my device.", 
      image: null
    }, 
    {
      question: "How many devices can I register?", 
      answer: "Currently a user may only have one device registered at a time. To move your registration from one device to another, you must first deregister your current device and then follow the normal steps for registering the new device.", 
      image: null
    }, 
    {
      question: "How many users can use a single mobile device?", 
      answer: "Only one user per device. It is not currently recommended you use a shared device for multi-factor authentication. However, multi-user support is a feature that will be added in a future version of the application.", 
      image: null
    }
  ]
  },
  currentTheme: function () {
    if (this[this.themeKey]) {
      return this[this.themeKey]
    } else {
      return this.army
    }
  },
  setCurrentTheme: function (newTheme) {
    this.themeKey = newTheme
  }
}
