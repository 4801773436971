import React, { Component } from "react"

import backgroundImage from "../assets/SeizingATownExercise.jpg"
export default class CreateUser extends Component {
  constructor() {
    super()
    this.state = {
      firstName: "",
      lastName: "",
      userName: "",
      password: ""
    }
  }

  componentDidMount() {}

  createUser = () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL

    fetch(
      `${BASE_URL}/createUser?firstName=${this.state.firstName}&lastName=${this.state.lastName}&email=${this.state.userName}&password=${this.state.password}`
    )

    this.setState({
      firstName: "",
      lastName: "",
      userName: "",
      password: ""
    })

    this.props.history.push({
      pathname: "/register"
    })
  }

  handleChangeUserName = (sender) => {
    this.setState({ userName: sender.target.value })
  }

  handleChangeFirstName = (sender) => {
    this.setState({ firstName: sender.target.value })
  }

  handleChangeLastName = (sender) => {
    this.setState({ lastName: sender.target.value })
  }

  handleChangePassword = (sender) => {
    this.setState({ password: sender.target.value })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.createUser()
  }

  render() {
    return (
      <div>
        <img
          src={backgroundImage}
          alt=""
          style={{ position: "absolute", top: 0, left: 0, zIndex: -1, width: "100%" }}
        />

        <div
          style={{
            backgroundColor: "#FFFF00",
            alignItems: "center"
          }}>
          POC Development Environment - no real data backs this site.
          <br />
          This page creates a test user only. Click <a href="/">here</a> to return to the{" "}
          <a href="/">login page</a>.
        </div>

        <div
          style={{
            backgroundColor: "#FFFFFFaa",
            borderRadius: 8,
            border: "1px solid #000000",
            width: "400px",
            alignSelf: "center",
            marginLeft: 20,
            marginTop: 20,
            padding: 5,
            paddingBottom: 20
          }}>
          <p
            style={{
              flex: 1,
              display: "block",
              alignItems: "center",
              justifyContent: "center",
              justifyItems: "center",
              marginLeft: 10
            }}>
            <h3>Create an account...</h3>
          </p>

          <form onSubmit={this.handleSubmit} style={{ marginLeft: 40 }}>
            <label>
              <div>First Name:</div>
              <input
                type="text"
                style={{ width: 200 }}
                value={this.state.firstName}
                onChange={this.handleChangeFirstName}
              />
            </label>
            <br />
            <label>
              <div>Last Name:</div>
              <input
                type="text"
                style={{ width: 200 }}
                value={this.state.lastName}
                onChange={this.handleChangeLastName}
              />
            </label>
            <br />
            <label>
              <div>Username:</div>
              <input
                type="text"
                style={{ width: 200 }}
                value={this.state.userName}
                onChange={this.handleChangeUserName}
              />
            </label>
            <br />
            <label>
              <div>Password:</div>
              <input
                type="text"
                style={{ width: 200 }}
                value={this.state.password}
                onChange={this.handleChangePassword}
              />
              <div style={{ fontSize: 8 }}>(clear text password, not secure, for testing only)</div>
            </label>
            <br />
            <input style={{ width: 80 }} type="submit" value="Submit" />
          </form>
        </div>
      </div>
    )
  }
}
