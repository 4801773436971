import React, { Component } from "react"
import { Themes } from "../managers/ThemeManager"
import OmniAural from "omniaural"
import { SuccessfulRegistrationBanner } from "../components/SuccessFulRegistration"
import { Header } from "./screen-components/PageHeader"
import { ContactInfoText } from "../components/ContactInfoText"

class SponsoredRegisteredPFA extends Component {
  render() {
    const { history } = this.props
    const images = Themes.currentTheme().images
    return (
      <div>
        <Header />
        <img src={images.appHeroImage} className="app-hero-image" alt="app hero" />
        <SuccessfulRegistrationBanner
          description={Themes.currentTheme().strings.successfull_registration_banner_description}
        />
        <div id="registered-title-bottom">
          <button
            type="button"
            className="register-button deregister"
            onClick={() => {
              history.push("/login")
            }}>
            <p>LOGIN TO MANAGE ACCOUNT</p>
          </button>
        </div>
        <div className="body-container">
          <div className="body-container-head">
            <br />
            <br />
            <p>{`Issues with the ${Themes.currentTheme().strings.appName} service?`}</p>
            <ContactInfoText/>
          </div>
          <div className="body-container-body half-width">
            <h2>SUPPORT FAQ</h2>
            <p className="italic">
              {`Answers to common questions about the ${
                Themes.currentTheme().strings.appName
              } and service.`}
            </p>
            <br />
            <p className="bold subhead">
              {Themes.currentTheme().strings.service_question_01_question}
            </p>
            <p>{Themes.currentTheme().strings.service_question_01_answer.replace("#contact-info", OmniAural.state?.config?.helpdesk_contact?.value())}</p>
            <br />
            <p className="bold subhead">
              {Themes.currentTheme().strings.service_question_02_question}
            </p>
            <p>{Themes.currentTheme().strings.service_question_02_answer}</p>
            <br />
            <p className="bold subhead">
              {Themes.currentTheme().strings.service_question_03_question}
            </p>
            <p>{Themes.currentTheme().strings.service_question_03_answer}</p>
            <br />
            <p
              className="text-link"
              onClick={() => {
                history.push({
                  pathname: "/terms"
                })
              }}>
              {`End User License Agreement and
            Terms &
            Conditions`}
            </p>
            <br />
            <p
              className="text-link"
              onClick={() => {
                window.location.replace("/logout")
              }}>
              {`Logout`}
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default SponsoredRegisteredPFA
