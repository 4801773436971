import OmniAural from "omniaural"

OmniAural.addAction("setUserFromResponse", (response) => {
  const user = { ...response, ...response.user }
  OmniAural.state.user.set(user)
})

OmniAural.addAction("isUserMFARegistered", () => {
  const user = OmniAural.state.user?.value() || {}
  if (!user.default_device_id) {
    return false
  }

  const registered_device = user?.devices?.find(
    (device) => device.device_id === user?.default_device_id
  )

  return registered_device && registered_device?.second_factor
})

OmniAural.addAction("isUserPFARegistered", () => {
  const user = OmniAural.state.user?.value() || {}
  if (!user.default_device_id) {
    return false
  }
  const registered_device = user?.devices?.find(
    (device) => device.device_id === user?.default_device_id
  )

  return registered_device && registered_device?.primary_factor
})
