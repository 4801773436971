import React from "react"
import "./LoadingSpinner.css"

const LoadingSpinner = ({ startup }) => {
  let startupStyle = {}
  if (startup) {
    startupStyle = {
      borderColor: "#adadad",
      borderTopColor: "#000"
    }
  }

  return (
    <div className="loaderContainer">
      <div className="loader" style={startupStyle}></div>
    </div>
  )
}

export default LoadingSpinner
