import { Constants } from "../Constants"
const mobileConnectUrl = process.env.REACT_APP_MC_URL
const mobileConnectAdminApiUrl = process.env.REACT_APP_ADMIN_URL

// ENDPOINTS
const GET_CONFIG = "/getConfig"
const GET_REGISTRATION_STATUS = "/getRegistrationStatus"
const GET_PF_REGISTRATION_STATUS = "/getPFRegistrationStatus"
const LOGIN = "/login"
const GET_ASSERTION = "/getAssertion"
const GET_ASSERTION_WITH_TOTP = "/getAssertionWithTotp"
const CHALLENGE_2ND_FACTOR = "/sendChallenge"
const LOGOUT = "/logout"
const GET_USER_INFO = "/getUserInfo"
const GET_SECOND_FACTOR_QR_DATA = "/getSecondFactorQRData"
const GET_UUID_BY_USER_EMAIL = "/register_session"
const GET_PRIMARY_FACTOR_QR_DATA = "/createRegistrationDeepLink"
const FILTER_ON_UID = "/filterOnUidPrefix"
const USER = "/user"
const CHALLENGES = "/challenges"
const DEREGISTER = "/deregister"
const SPF_SEND_SMS = "/spfSendSMS"

// We dont need to run the auth check on the getUserInfo call because that is done by
// The Authentication HOC on each screen that calls it
const AUTH_CHECK_EXCLUDED_ENDPOINTS = [
  GET_USER_INFO,
  GET_CONFIG,
  GET_PF_REGISTRATION_STATUS,
  GET_PRIMARY_FACTOR_QR_DATA
]

// API METHODS
export const getConfig = () => {
  return request({
    endpoint: GET_CONFIG,
    options: {
      method: "GET"
    }
  })
}

export const getRegistrationStatus = (uid) => {
  return request({
    endpoint: GET_REGISTRATION_STATUS,
    options: {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        uid: uid || ""
      },
      credentials: "same-origin"
    }
  })
}

export const getPFRegistrationStatus = (uid, uuid) => {
  let endpoint = GET_PF_REGISTRATION_STATUS

  let queryParams = []

  if (uuid) {
    queryParams.push(`uuid=${uuid}`)
  }

  if (queryParams.length) {
    endpoint += "?" + queryParams.join("&")
  }

  return request({
    endpoint: endpoint,
    options: {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        uid: uid || ""
      },
      credentials: "same-origin"
    }
  })
}

export const getAssertion = (uid, params = {}) => {
  let endpoint = GET_ASSERTION
  let queryParams = []

  for (const key in params) {
    queryParams.push(`${key}=${params[key]}`)
  }

  if (queryParams.length) {
    endpoint += "?" + queryParams.join("&")
  }

  return request({
    endpoint,
    options: {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        uid: uid || ""
      },
      credentials: "same-origin"
    }
  })
}

export const getAssertionWithTotp = (uid, totp, params = {}) => {
  let endpoint = GET_ASSERTION_WITH_TOTP
  let queryParams = []

  for (const key in params) {
    queryParams.push(`${key}=${params[key]}`)
  }

  if (queryParams.length) {
    endpoint += "?" + queryParams.join("&")
  }

  return request({
    endpoint,
    options: {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        uid: uid || ""
      },
      body: JSON.stringify({
        totp
      })
    }
  })
}

// added deregister endpoint
export const deregister = (deviceID) => {
  const reqBody = JSON.stringify({ device_id: deviceID })
  return request({
    endpoint: DEREGISTER,
    options: {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: reqBody,
      credentials: "same-origin"
    }
  })
}

export const login = (username, password) => {
  return request({
    endpoint: LOGIN,
    options: {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        username,
        password
      })
    }
  })
}

export const logout = (uid) => {
  return request({
    endpoint: LOGOUT,
    options: {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        uid: uid || ""
      },
      credentials: "same-origin"
    }
  })
}

export const getUserInfo = (uid) => {
  return request({
    endpoint: GET_USER_INFO,
    options: {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        uid: uid || ""
      },
      credentials: "same-origin"
    }
  })
}

export const getSecondFactorQRData = (uid) => {
  return request({
    endpoint: GET_SECOND_FACTOR_QR_DATA,
    options: {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        uid: uid || ""
      },
      credentials: "same-origin"
    }
  })
}

export const getUUIDByUserEmail = (email) => {
  return request({
    endpoint: `${GET_UUID_BY_USER_EMAIL}?email=${email}`,
    options: {
      method: "GET"
    }
  })
}

export const getPrimaryFactorQRData = (uuid, totp) => {
  const requestBody = {}

  if (uuid) {
    requestBody.uuid = uuid
  }

  if (totp) {
    requestBody.totp = totp
  }

  return request({
    endpoint: `${GET_PRIMARY_FACTOR_QR_DATA}`,
    options: {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type": "application/json"
      }
    }
  })
}

export const filterByLastSix = (searchTerm) => {
  return request({
    endpoint: FILTER_ON_UID + "?uid=" + searchTerm,
    options: {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    },
    customUrl: mobileConnectAdminApiUrl
  })
}

export const user = (regKey) => {
  return request({
    endpoint: USER + "?registration_key=" + regKey,
    options: {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    },
    customUrl: mobileConnectAdminApiUrl
  })
}

export const challenges = (regKey) => {
  return request({
    endpoint: CHALLENGES + "?registration_key=" + regKey,
    options: {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    },
    customUrl: mobileConnectAdminApiUrl
  })
}

export const deregisterUser = (regKey, deviceId, deregReason) => {
  return request({
    endpoint: DEREGISTER + "?registration_key=" + regKey + "&deviceId=" + deviceId + "&reason=" + deregReason,
    options: {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      }
    },
    customUrl: mobileConnectAdminApiUrl
  })
}

export const challenge2ndFactor = (uid) => {
  return request({
    endpoint: CHALLENGE_2ND_FACTOR,
    options: {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        uid: uid || ""
      },
      credentials: "same-origin"
    }
  })
}

export const spfSendSMS = (uuid) => {
  return request({
    endpoint: SPF_SEND_SMS,
    options: {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ uuid })
    }
  })
}

// NOT FOR PRODUCTION USE. DEV ONLY
export const getUser = (device_id) => {
  return request({
    endpoint: "/user?device_id=" + device_id,
    options: {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    }
  })
}

export const getPFAuthURL = async () => {
  return request({
    endpoint: "/createAuthenticationDeepLink",
    options: {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      }
    }
  })
}

export const deregisterPasscode = async (device_id) => {
  return request({
    endpoint: "/deregisterPasscodeWeb",
    options: {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ device_id })
    }
  })
}

const request = ({ endpoint, options, customUrl }) => {
  let url = customUrl || mobileConnectUrl

  let runAuthCheck = true
  if (AUTH_CHECK_EXCLUDED_ENDPOINTS.includes(endpoint)) {
    runAuthCheck = false
  }

  return fetch(url + endpoint, options).then(async (resp) => {
    const responseObj = await resp.json()

    if (runAuthCheck && responseObj?.code === Constants.ERROR_CODES.USER_NOT_AUTHENTICATED) {
      if (!Constants.IS_DEV) {
        window.location.assign("/login")
      }
    }

    return responseObj
  }).catch((err) => {
    if(Constants.IS_DEV) {
      if(err.message === "Failed to fetch") {
        window.alert("You are probably not running the backend locally.\nFollow the steps in the README.")
      }
    }

    throw err
  })
}
