import React, { Component } from "react"
import { Themes } from "../managers/ThemeManager"
import { deregister } from "../services"
import { withPermissions } from "../components/AuthenticationComponent"
import OmniAural from "omniaural"
import { Constants } from "../Constants"
import { SuccessfulRegistrationBanner } from "../components/SuccessFulRegistration"
import { Header } from "./screen-components/PageHeader"
import { ContactInfoText } from "../components/ContactInfoText"

class RegisteredPFA extends Component {

  validateDeregistration = () => {
    const confirmed = window.confirm("Are you sure you would like to deregister your device?")

    if(confirmed) {
      const { history } = this.props
      const deviceID = OmniAural.state.user?.default_device_id?.value()

      deregister(deviceID)
        .then(() => {
          const defaultRegistrationFactor =
            OmniAural.state?.config?.default_registration_factor?.value()

          let redirectTo = "/register"
          if (defaultRegistrationFactor === "primary") {
            redirectTo = "/register_pfa"
          }

          history.push(redirectTo)
        })
        .catch((e) => {
          console.log("Error occurred while attempting to deregister: ", e)
        })
    }
  }

  renderListItem = (question, answer) => {
    return (
      <>
      {question && 
          <p className="bold subhead">
              {Themes.currentTheme().strings.service_question_01_question}
          </p>
      }
      {answer && 
          <>
            <p>{Themes.currentTheme().strings.service_question_01_answer.replace("#contact-info", OmniAural.state?.config?.helpdesk_contact?.value())}</p>
            <br/>
          </>
      }
      </>
    )
  }

  render() {
    const { history } = this.props
    const images = Themes.currentTheme().images
    return (
      <div>
        <Header />
        <img src={images.appHeroImage} className="app-hero-image" alt="app hero" />
        <SuccessfulRegistrationBanner
          description={Themes.currentTheme().strings.register_success_message_pfa}
        />
        <div id="registered-title-bottom">
          <p>Need to switch to a different device, or need to reinstall the app?</p>
          <p>Simply deregister first and then reregister.</p>
          <br />
          <button
            type="button"
            className="register-button deregister"
            onClick={this.validateDeregistration}>
            <p>DEREGISTER MY CURRENT DEVICE</p>
          </button>
        </div>
        <div className="body-container">
          <div className="body-container-head">
            <br />
            <br />
            <p>{`Issues with the ${Themes.currentTheme().strings.appName} service?`}</p>
            <ContactInfoText/>
          </div>
          <div className="body-container-body half-width">
            <h2>{Themes.currentTheme().strings.registered_faq_title.replace("#app-name", Themes.currentTheme().strings.appName)}</h2>
            <p className="italic">
              {Themes.currentTheme().strings.registered_faq_subtitle.replace("#app-name", Themes.currentTheme().strings.appName)}
            </p>
            <br />
            {this.renderListItem(Themes.currentTheme().strings.service_question_01_question, Themes.currentTheme().strings.service_question_01_answer.replace("#contact-info", OmniAural.state?.config?.helpdesk_contact?.value()))}
            {this.renderListItem(Themes.currentTheme().strings.service_question_02_question, Themes.currentTheme().strings.service_question_02_answer)}
            {this.renderListItem(Themes.currentTheme().strings.service_question_03_question, Themes.currentTheme().strings.service_question_03_answer)}
            <p
              className="text-link"
              onClick={() => {
                history.push({
                  pathname: "/faq"
                })
              }}>
              {`FAQ Page`}
            </p>
            <br />
            <p
              className="text-link"
              onClick={() => {
                history.push({
                  pathname: "/terms"
                })
              }}>
              {`End User License Agreement and
            Terms &
            Conditions`}
            </p>
            <br />
            <p
              className="text-link"
              onClick={() => {
                window.location.replace("/logout")
              }}>
              {`Logout`}
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default withPermissions(RegisteredPFA, [
  Constants.PERMISSION_TYPES.AUTHENTICATED,
  Constants.PERMISSION_TYPES.PFA_REGISTERED
])
