import React from "react"
import OmniAural from "omniaural"
import { Themes } from "../../managers/ThemeManager"

export const Header = ({forceHide}) => {
  const config = OmniAural.state.config.value()
  const showTestBanner = config.site_type && config.site_type !== "production"
  const images = Themes.currentTheme().images
  return (
    <div className="header-body" style={{ backgroundImage: `url(${images.headerBackground})` }}>
      <img src={images.mobileConnectLogo} className="header-image" alt="mobile connect logo" />
      {!forceHide && showTestBanner && (
        <img src={require("../../assets/Test-Banner.png")} className="header-test-banner" />
      )}
    </div>
  )
}
