exports.Constants = {
  ERROR_CODES: {
    BAD_PARAMS: "-1201",
    STALE_DATA: "-1202",
    BAD_QR: "-1203",
    BAD_METHOD: "-1204",
    PUSH_FAILED: "-1205",
    AWAITING_MOBILE_RESPONSE: "-1206",
    CHALLENGE_DENIED: "-1207",
    UNKNOWN_ERROR: "-1208",
    INVALID_TOTP: "-1209",
    INVALID_TOTP_RESET: "-1229",
    TOTP_TRIES_EXCEEDED: "-1299",
    NOT_REGISTERED: "1210", // intentionally positive - this is used by portal/sso
    UNEXPECTED_AAL: "-1211",
    PASSCODE_ALREADY_REGISTERED: "-1212",
    INVALID_PASSCODE: "-1214",
    INVALID_PASSCODE_DEREGISTERED: "-1215",
    MISSING_SSO_HEADERS: "-1216",
    CHALLENGE_RESPONSE_TIMEOUT: "-1217",
    USER_NOT_AUTHENTICATED: "-1218",
    FIREBASE_DUPLICATES_DETECTED: "-9999",
    BAD_SIGNATURE: "-6666",
    POLLING_TIMEOUT: "9999"
  },
  PERMISSION_TYPES: {
    AUTHENTICATED: "AUTHENTICATED",
    MFA_REGISTERED: "MFA_REGISTERED",
    PFA_REGISTERED: "PFA_REGISTERED",
    ADMINISTRATOR: "ADMINISTRATOR"
  },
  COOKIE_ID: "duid",
  IS_DEV: process.env.NODE_ENV === "development" || !!process.env.REACT_APP_IS_DEV,
  DEFAULT_QR_SIZE: 320
}
