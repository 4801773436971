import React from "react"
import OmniAural from "omniaural"
import { getUserInfo, user as getUserForAdmin } from "../services"
import { Constants } from "../Constants"
import LoadingSpinner from "./LoadingSpinner"
import { Buffer } from "buffer"
import ClassificationBanner from "./ClassificationBanner"

export function withPermissions(Component, permissions) {
  return class AuthenticatedComponent extends React.Component {
    state = {
      loaded: false
    }

    isUserUnAuthed = async (userInfoResponse) => {
      return userInfoResponse.code === Constants.ERROR_CODES.USER_NOT_AUTHENTICATED
    }

    isUserMFARegistered = async (user) => {
      if (!user?.default_device_id) {
        return false
      }

      const registered_device = user?.devices?.find(
        (device) => device.device_id === user?.default_device_id
      )

      return registered_device && registered_device?.second_factor
    }

    isUserPFARegistered = async (user) => {
      if (!user?.default_device_id) {
        return false
      }
      const registered_device = user?.devices?.find(
        (device) => device.device_id === user?.default_device_id
      )

      return registered_device && registered_device?.primary_factor
    }

    isUserAdmin = async (user) => {
      const { idp_id, uid } = user
      try {
        const res = await getUserForAdmin(idp_id + "_" + uid)
        if (res.code !== "1200") {
          return false
        }

        return true
      } catch (err) {
        return false
      }
    }

    async componentDidMount() {
      if (!permissions?.length) {
        throw new Error(
          "Permissions options required. Must be at least one of " +
            Object.values(Constants.PERMISSION_TYPES).join(", ") +
            "."
        )
      }

      const uid = this.props?.cookies?.get(Constants.COOKIE_ID)

      // In dev, this uid needs to be passed into getUserInfo in order for the call
      // to correctly respond
      let uidString = null
      if (uid) {
        uidString = Buffer.from(uid, "base64").toString()
      }

      const resp = await getUserInfo(uidString)

      const user = { ...resp.user, ...resp }
      let reRoute = null
      let reRouteParams = {}

      if (permissions.includes(Constants.PERMISSION_TYPES.AUTHENTICATED)) {
        const userUnauthenticated = await this.isUserUnAuthed(resp)
        if (userUnauthenticated) {
          reRoute = "/login"
          reRouteParams = {
            forwardPath: this.props.history.location.pathname
          }
        }
      }

      if (!reRoute && permissions.includes(Constants.PERMISSION_TYPES.MFA_REGISTERED)) {
        const isUserMFARegistered = await this.isUserMFARegistered(user)

        if (!isUserMFARegistered) {
          reRoute = "/register"
        }
      }

      if (!reRoute && permissions.includes(Constants.PERMISSION_TYPES.PFA_REGISTERED)) {
        const isUserPFARegistered = await this.isUserPFARegistered(user)

        if (!isUserPFARegistered) {
          reRoute = "/register_pfa"
        }
      }

      if (!reRoute && permissions.includes(Constants.PERMISSION_TYPES.ADMINISTRATOR)) {
        const isUserAnAdmin = await this.isUserAdmin(user)

        if (!isUserAnAdmin) {
          reRoute = "/login"
        }
      }

      if (!reRoute) {
        OmniAural.setUserFromResponse(resp)
        this.setState({ loaded: true })
      } else {
        this.props.history.replace(reRoute, reRouteParams)
      }
    }

    render() {
      return !this.state.loaded ? (
        <LoadingSpinner />
      ) : (
        <>
          <ClassificationBanner position={"top"} />
          <Component {...this.props} />
          <ClassificationBanner position={"bottom"} />
        </>
      )
    }
  }
}
