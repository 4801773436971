import React from "react"

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  componentDidCatch(error) {
    this.setState({
      error: error
    })
  }

  render() {
    if (this.state.error) {
      window.location.assign("/error.html")
    }

    return this.props.children
  }
}

export default ErrorBoundary
