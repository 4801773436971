import React, { Component } from "react"
import { getUUIDByUserEmail, login } from "../services"
import "./Login.css"
import OmniAural from "omniaural"
import { Themes } from "../managers/ThemeManager"
import { Buffer } from "buffer"
import { Constants } from "../Constants.js"

class Login extends Component {
  intervalTimer = null
  constructor() {
    super()
    const images = Themes.currentTheme().images
    this.state = {
      username: "",
      password: "",
      errorCode: null,
      message: "",
      currentImage: 0,
      images: [
        images.loginBackground1,
        images.loginBackground2,
        images.loginBackground3,
        images.loginBackground4,
        images.loginBackground5
      ]
    }
  }

  themeStrings = {
    army: {
      appName: "Army MobileConnect app",
      appNameNoApp: "Army MobileConnect",
      contactNumber: "1-866-335-ARMY",
      contactAbbrev: "AESD",
      enterpriseAbbrev: "EAMS-A"
    },

    airforce: {
      appName: "Air Force MobileConnect app",
      appNameNoApp: "Air Force MobileConnect",
      contactNumber: "1-800-XXX-XXXX",
      contactAbbrev: "AFESD",
      enterpriseAbbrev: "XYZ-PDQ"
    }
  }

  switchImage = () => {
    if (this.state.currentImage < this.state.images.length - 1) {
      this.setState({
        currentImage: this.state.currentImage + 1
      })
    } else {
      this.setState({
        currentImage: 0
      })
    }
  }

  componentDidMount() {
    this.intervalTimer = setInterval(this.switchImage, 4000)
  }

  componentWillUnmount() {
    if (this.intervalTimer) {
      clearInterval(this.intervalTimer)
    }
  }

  setDevCookie = (uid) => {
    if (uid) {
      this.props.cookies.set(Constants.COOKIE_ID, Buffer.from(uid).toString("base64"), {
        expirces: 3600 * 2 // Two hours
      })
    }
  }

  performLogin = (e) => {
    e?.preventDefault()
    login(this.state.username, this.state.password)
      .then((response) => {
        if (response.code === "1200") {
          OmniAural.state.user.set(response.user)

          this.setDevCookie(response.user?.uid)

          const defaultFactor = OmniAural.state?.config?.default_registration_factor?.value()

          let path = "/register_pfa"

          if (defaultFactor === "second") {
            path = "/register"
          }

          if (this.props.history?.location?.state?.forwardPath) {
            path = this.props.history.location.state.forwardPath
          }

          this.props.history.push({
            pathname: path
          })
        } else if (response.code === Constants.ERROR_CODES.AWAITING_MOBILE_RESPONSE) {
          OmniAural.state.user.set(response.user)

          this.setDevCookie(response.user?.uid)

          this.props.history.push({
            pathname: "/challenge",
            state: {
              username: this.state.username,
              forwardPath: this.props.history?.location?.state?.forwardPath
            }
          })
        } else {
          this.setState({
            username: "",
            password: "",
            errorCode: response.code,
            message: response.message
          })
        }
      })
      .catch((error) => {
        this.setState({
          username: "",
          password: "",
          errorCode: error.code,
          message: error.message
        })
      })
  }

  presentLoginQR = async () => {
    this.props.history.push("/challenge_pfa", {
      forwardPath: this.props.history?.location?.state?.forwardPath
    })
  }

  requestCredentials = () => {
    let username = window.prompt("Enter username:", "")
    let password = window.prompt("Enter password:", "")
    this.setState({ username, password }, this.performLogin)
  }

  spfaRegisteration = async () => {
    let email = window.prompt("Enter username")
    let uuid = null
    const response = await getUUIDByUserEmail(email)
    uuid = response.payload
    this.props.history.push(`/spfa_register?uuid=${uuid}`)
  }

  renderAFPage = () => {
    return (
      <div className="af-body">
        <nav id="globalNav">
          <img
            id="globalNavLogo"
            src={require("../assets/1809_C1_logo_tagline_white.png")}
            height="75px"
            alt="CloudOne Logo"
          />
        </nav>

        <div id="mainContent">
          <section className="titleSection">
            <h1 className="loginTitle" style={{ textAlign: "center" }}>
              Cloud One SSO
            </h1>
          </section>

          <section className="contentSection">
            <div
              id="CAC / ECA Login"
              className="cac-login-wrapper"
              style={{ position: "relative" }}>
              <br />
              <h2 style={{ textAlign: "center" }}>Insert your CAC / ECA to begin your login</h2>

              <div className="loginSection">
                <img
                  className="cacImage"
                  src={require("../assets/1809_CAC-image-100.jpeg")}
                  alt="CAC Outline"
                  style={{ textAlign: "center" }}
                />
              </div>
              <div className="supportSection">
                <h3>MEMBERSHIP AND SUPPORT INFORMATION</h3>
                <ul>
                  <li>
                    <a href="https://www.my.af.mil/afp/netstorage/login_page_files_cloud_one/reg-requirements.html">
                      View Air Force Portal Registration Requirements
                    </a>
                  </li>
                  <li>
                    <a href="https://www.my.af.mil/afp/netstorage/login_page_files_cloud_one/help-desk.html">
                      Contact the Help Desk
                    </a>
                  </li>
                </ul>
              </div>
              <br clear="all" />
            </div>
          </section>
          <div style={{ textAlign: "center" }}>
            <p id="uParam"></p>
            <div id="af-login-buttons">
              <button
                name="button"
                type="submit"
                className="btn-primary"
                onClick={this.requestCredentials}>
                Login
              </button>
              <div style={{ width: 50 }} />
              <button
                name="button"
                type="submit"
                className="btn-primary"
                onClick={this.presentLoginQR}>
                Login with MobileConnect
              </button>
              <div style={{ width: 50 }} />
              <button
                name="button"
                type="submit"
                className="btn-primary"
                onClick={this.spfaRegisteration}>
                Login with Sponsored Link
              </button>
            </div>

            <p style={{ textAlign: "left", marginTop: 30, marginBottom: 30 }}>
              The security accreditation level of this site is UNCLASSIFIED// FOUO and below. Do not
              process, store, or transmit information classified above the accreditation level of
              this system. Privacy Act Information: information accessed through this system must be
              protected in accordance with the Privacy Act of 1974, as amended, and AFI 33-332.
            </p>
          </div>
        </div>

        <footer>
          <div className="footerSection">
            You are accessing a U.S. Government (USG) Information System (IS) that is provided for
            USG-authorized use only. By using this IS (which includes any device attached to this
            IS), you consent to the following conditions:
            <ul>
              <li>
                The USG routinely intercepts and monitors communications on this IS for purposes
                including, but not limited to, penetration testing, COMSEC monitoring, network
                operations and defense, personnel misconduct (PM), law enforcement (LE), and
                counterintelligence (CI) investigations
              </li>
              <li>At any time, the USG may inspect and seize data stored on this IS.</li>
              <li>
                Communications using, or data stored on, this IS are not private, are subject to
                routine monitoring, interception, and search, and may be disclosed or used for any
                USG authorized purpose.
              </li>
              <li>
                This IS includes security measures (e.g., authentication and access controls) to
                protect USG interests--not for your personal benefit or privacy.
              </li>
              <li>
                NOTICE: There is the potential that information presented and exported from the AF
                Portal contains FOUO or Controlled Unclassified Information (CUI). It is the
                responsibility of all users to ensure information extracted from the AF Portal is
                appropriately marked and properly safeguarded. If you are not sure of the safeguards
                necessary for the information, contact your functional lead or Information Security
                Officer.
              </li>
              <li>
                Notwithstanding the above, using this IS does not constitute consent to PM, LE or CI
                investigative searching or monitoring of the content of privileged communications,
                or work product, related to personal representation or services by attorneys,
                psychotherapists, or clergy, and their assistants. Such communications and work
                product are private and confidential. See
                <b>User Agreement</b>
                for details.
              </li>
            </ul>
          </div>
          Last Modified: 19 JANUARY 2021
        </footer>
      </div>
    )
  }

  render() {
    let loginError = null
    if (this.state.errorCode === Constants.ERROR_CODES.BAD_PARAMS) {
      loginError = (
        <div className="login-error">
          <p>Unknown user or invalid password</p>
        </div>
      )
    } else if (this.state.errorCode != null) {
      loginError = (
        <div className="login-error">
          <p>An unexpected error occurred</p>
          <p> {this.state.message} </p>
        </div>
      )
    }

    if (OmniAural.state.config.theme.value() === "airforce") {
      return this.renderAFPage()
    }

    return (
      <div className="login">
        <div id="wrap">
          <div id="background-image">
            <img
              id="background-inner-image1"
              src={this.state.images[this.state.currentImage]}
              style={{ opacity: "1", right: "0%" }}
              alt=""></img>
          </div>

          <div id="logo-image-container">
            <img
              id="logo-image"
              src={Themes.currentTheme().images.eamsImage}
              alt="EAMS-A logo"></img>
          </div>

          <div id="content">
            <h2>
              {`FAKE ${Themes.currentTheme().strings.enterpriseAbbrev} Login`}
              <div id="mobile-newline"></div>Single Sign-On
            </h2>

            {loginError}

            <form
              id="login_form"
              autoComplete="off"
              style={{ display: "inline" }}
              onSubmit={this.performLogin}
              acceptCharset="UTF-8">
              <fieldset className="login-fieldset">
                <legend>Login</legend>
                <div>
                  <label>Username</label>
                  <br></br>
                  <input
                    type="text"
                    name="sso_session[username]"
                    id="sso_session_username"
                    onChange={(e) =>
                      this.setState({
                        username: e.target.value
                      })
                    }
                    value={this.state.username}></input>
                </div>

                <div>
                  <label>Password</label>
                  <br></br>
                  <input
                    type="password"
                    id="sso_session_password"
                    onChange={(e) =>
                      this.setState({
                        password: e.target.value
                      })
                    }
                    value={this.state.password}></input>
                </div>
              </fieldset>

              <div id="login-buttons">
                <button
                  name="button"
                  type="submit"
                  value="Submit"
                  id="login"
                  className="idp-15 akam-button">
                  Login
                </button>
                <button
                  type="button"
                  onClick={this.presentLoginQR}
                  id="pki-login"
                  className="idp-15 akam-button">
                  Login with MobileConnect
                </button>
                <button
                  name="button"
                  type="submit"
                  id="pki-login"
                  className="akam-button"
                  onClick={this.spfaRegisteration}>
                  Login with Sponsor link
              </button>
              </div>
            </form>
            <div className="login-support">
              <p>
                Trouble logging in?
                <br></br>
                <a className="loginLink" href="/">
                  Reset Password
                </a>
                <a className="loginLink" href="/createUser">
                  Create Account
                </a>
              </p>
              <div id="aesd">
                For assistance please
                <br></br>
                {`contact the ${Themes.currentTheme().strings.contactAbbrev}`}
                <br></br>
                <a href="https://aesd-w.army.mil/" style={{ float: "right" }}>
                  <img
                    src={Themes.currentTheme().images.aesdImage}
                    id="aesd-logo"
                    alt="AESD Worldwide"></img>
                </a>
                <div className="login-support-footnote" style={{ clear: "right" }}>
                  <br></br>
                  {`(${Themes.currentTheme().strings.contactAbbrev} portal account setup required)`}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Login
