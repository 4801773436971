import OmniAural from "omniaural"
import React, { useCallback, useEffect } from "react"
import { useState } from "react"
import { Buffer } from "buffer"
import { Constants } from "../Constants"
import { getUser } from "../services"
import "./SecureContent.css"

const SecureContent = ({ history, location, cookies }) => {
  const [userName, setUserName] = useState(null)
  const [deviceIdExists, setDeviceIdExists] = useState(false)
  const searchArr = location?.search?.split("=")
  const device_id = searchArr?.[1]

  const getFirebaseCookieValue = (cookieArr) => {
    let firebaseCookie = cookieArr.find((cookie) => cookie.includes("firebase_auth"))
    if (firebaseCookie) {
      const value = firebaseCookie.split("=")
      return value[1] ?? null
    }
    return null
  }

  const getData = useCallback(
    (deviceId) => {
      if (deviceId.length <= 0) {
        return "No device ID given."
      }

      getUser(deviceId).then((response) => {
        console.log("Device ID match!", response.userInfo)
        OmniAural.state.user.set(response.userInfo)
        if (!cookies.get(Constants.COOKIE_ID)) {
          cookies.set(Constants.COOKIE_ID, Buffer.from(response.userInfo.uid).toString("base64"), {
            expirces: 3600 * 2 // Two hours
          })
        }
        setUserName(response.userInfo.first_name)
        setDeviceIdExists(true)
      })
    },
    [cookies]
  )

  useEffect(() => {
    if (device_id) {
      getData(device_id)
    } else {
      let allCookies = document.cookie.split(";")
      const firebaseCookieVal = getFirebaseCookieValue(allCookies)

      if (firebaseCookieVal) {
        getData(firebaseCookieVal)
      }
    }
  }, [device_id, getData])

  return (
    <div className="secure-content-body">
      <h1 style={{ color: "black" }}>SECURE CONTENT</h1>
      {deviceIdExists ? (
        <h3 style={{ color: "black" }}>{`Welcome, ${userName}`}</h3>
      ) : (
        <h3 style={{ color: "black" }}>Fetching...</h3>
      )}
      <div>
        <button
          style={{ color: "blue", margin: "20px 0 0 0px" }}
          onClick={() => {
            history.replace("/logout")
          }}>
          Logout
        </button>

        <br />
      </div>
    </div>
  )
}

export default SecureContent
