import React, { Component } from "react"
import { Themes } from "../managers/ThemeManager"
import QRCode from "qrcode.react"
import "./AppDownload.css"
import { Header } from "./screen-components/PageHeader"

const IOS = "ios"
const ANDROID = "android"
const UNSUPPORTED = "unsupported"

const buttonStyle = {
  display: "flex",
  marginTop: 30,
  padding: 15,
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  marginBottom: 10,
  fontSize: 16,
  color: "black",
  textDecoration: "none",
  fontWeight: "bold"
}

const strings = {
  ios: {
    armyurl:
      "itms-services://?action=download-manifest&url=https://armyauth.page.link/dev-manifest-army",
    afurl:
      "itms-services://?action=download-manifest&url=https://armyauth.page.link/dev-manifest-af",
    reqs: "Requires iOS 13.0 or newer.",
    instructions: (
      <div>
        If download or installation fails, contact Isobar Public Sector for instructions on how to
        add your device on the list of approved devices.
      </div>
    )
  },
  android: {
    armyurl: "https://armyauth.page.link/android-app-dev",
    afurl: "https://armyauth.page.link/AGse",
    reqs: "Requires Android 10 or newer.",
    instructions: (
      <div style={{ color: "#555" }}>
        <span style={{ color: "black" }}>
          When the app completes downloading, tap on the download and then tap install.
        </span>
        <br />
        <br />
        On Android 10 or higher, you may get a dialog such as "Chrome, For your security, your phone
        is not allowed to install unknown apps from this source".
        <br />
        <br />
        Click the <span style={{ color: "black" }}>Settings</span> button of that dialog and toggle{" "}
        <span style={{ color: "black" }}>Allow from this source</span>.<br />
        <br />
        Alternatively you can navigate to
        <span style={{ color: "black" }}>{`Phone Settings > Apps & Notifications`}</span>
        Find the browser application in the list, tap{" "}
        <span style={{ color: "black" }}>Advanced</span> then
        <span style={{ color: "black" }}>Install unknown apps</span> then toggle{" "}
        <span style={{ color: "black" }}>Allow from this source</span>.<br />
      </div>
    )
  },
  unsupported: {
    armyurl: "#",
    afurl: "#",
    reqs: "Unsupported device detected, requires iOS 13+ or Android 10+.",
    instructions: (
      <div>
        <QRCode style={{ margin: 40 }} value={window.location.href} />
        <br />
        To download the app, scan the above QR code on your iOS or Android device.
        <br />
        <br />
        <span style={{ color: "red" }}>For iOS</span>, open your camera and point it at the QR Code,
        then tap the banner that appears.
        <br />
        <span style={{ color: "red" }}>For Android</span>, use your QR scanning app or camera
        depending on your device.
        <br />
      </div>
    )
  }
}

export default class AppDownload extends Component {
  renderButtons = (platform) => {
    return (
      <div>
        {platform !== UNSUPPORTED ? (
          <>
            <a
              href={strings[platform].armyurl}
              style={{ ...buttonStyle, backgroundColor: Themes.army.colors.accent1 }}>
              Download Army MobileConnect
            </a>
            <a
              href={strings[platform].afurl}
              style={{ ...buttonStyle, backgroundColor: Themes.airforce.colors.accent1 }}>
              <span style={{ color: "white" }}>Download Airforce MobileConnect</span>
              <br />
              {Themes.currentTheme().strings.appNameNoApp}
            </a>
          </>
        ) : null}

        <div className="italic" style={{ color: "#888", fontSize: 14 }}>
          {strings[platform].reqs}
        </div>

        {strings[platform].instructions}
      </div>
    )
  }

  render() {
    let isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
    let ios = (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) || isIpad
    let android = /Android/.test(navigator.userAgent) && !window.MSStream
    let platform = ios ? IOS : android ? ANDROID : UNSUPPORTED

    return (
      <div style={{ display: "flex", flexDirection: "column", backgroundColor: "#fff" }}>
        <Header forceHide/>
        <div style={{ paddingLeft: 20, paddingRight: 20 }}>
          {platform === UNSUPPORTED ? (
            <div
              style={{
                borderColor: "#af2c2e",
                border: "solid",
                color: "#af2c2e",
                fontWeight: "bold",
                fontSize: 16,
                textAlign: "center",
                padding: 15,
                marginTop: 40,
                borderWidth: 2
              }}>
              {`The ${Themes.currentTheme().strings.appName} is not compatible with this device. `}(
              {navigator.userAgent})
            </div>
          ) : null}
          {this.renderButtons(platform)}
        </div>
      </div>
    )
  }
}
